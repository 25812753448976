import {
  Alert,
  Box,
  Grid,
  ThemeProvider,
  Typography,
  useMediaQuery,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";
import PageFooter from "../../Layout/PageFooter/PageFooter";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import Theme from "../../Theme/Theme";
import Button from "../../UI/Button/Button";
import TextBox from "../../UI/FormGroup/TextBox/TextBox";
import Loader from "../../UI/Loader/Loader";
import classes from "./Site.module.css";
import UploadButton from "../../UI/FormGroup/UploadButton/UploadButton";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import storageService from "../../utils/localStorageHelpers";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import CautionAlert from "../../utils/CautionAlert";
import { useDispatch } from "react-redux";
import { saveSite } from "../../../redux/site";
import CryptoJS from "crypto-js";
import CustomRadioGroup from "../../UI/FormGroup/RadioGroup/RadioGroup";
import ImageEdit from "./../../utils/ImageEdit/ImageEdit";
import Images from "./Images";
import CheckBoxes from "../../UI/CheckBox/CheckBoxes";

export default function EditSite() {
  const widthIs14 = useMediaQuery("(max-width:1279px)");
  const widthIs930 = useMediaQuery("(max-width:930px)");
  const widthIs1630 = useMediaQuery("(max-width:1630px)");
  const location = useLocation();

  const { id } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [success, setSuccess] = useState(null);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const [apiError, setApiError] = useState(null);

  const [name, setName] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const [hpio, setHpio] = useState(null);
  const [hpioError, setHpioError] = useState(null);
  // const [mimsIntegrated, setMimsIntegrated] = useState(false);
  const [backUrl, setBackUrl] = useState(null);
  const [backUrlError, setBackUrlError] = useState(null);
  const [frontUrl, setFrontUrl] = useState(null);
  const [frontUrlError, setFrontUrlError] = useState(null);
  const [abn, setAbn] = useState(null);
  const [abnError, setAbnError] = useState(null);
  const [businessName, setBusinessName] = useState(null);
  const [businessNameError, setBusinessNameError] = useState(null);
  const [addressLine1, setAddressLine1] = useState(null);
  const [addressLine2, setAddressLine2] = useState(null);
  const [addressOneError, setAddressOneError] = useState(null);
  const [suburb, setCity] = useState(null);
  const [suburbError, setCityError] = useState(null);
  const [phone, setphone] = useState(null);
  const [phoneError, setphoneError] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [postcode, setPostcode] = useState(null);
  const [postcodeError, setPostcodeError] = useState(null);
  const [state, setState] = useState(null);
  const [stateError, setStateError] = useState(null);
  const [country, setCountry] = useState(null);
  const [countryError, setCountryError] = useState(null);
  const [fax, setFax] = useState(null);
  const [faxError, setFaxError] = useState(null);
  const [headerLogo, setHeaderLogo] = useState(null);
  const [templateLogo, setTemplateLogo] = useState(null);
  const [headerLogoError, setHeaderLogoError] = useState(null);
  const [templateLogoError, setTemplateLogoError] = useState(null);
  const [deleteLogo, setDeleteLogo] = useState(null);
  const [headerDeleted, setHeaderDeleted] = useState(false);
  const [templateDeleted, setTemplateDeleted] = useState(false);
  const [description, setDescription] = useState(null);
  const [banner, setBanner] = useState(null);
  const [bannerDeleted, setBannerDeleted] = useState(null);
  const [medicineType, setMedicineType] = useState(["Allopathic"]);
  const [medicineTypeError, setMedicineTypeError] = useState(null);
  const [ipAdmission, setIpAdmission] = useState(false);

  const [fileUploadError, setFileUploadError] = useState(null);

  const [imageURL, setimageURL] = useState(null);

  const [data, setData] = useState(null);
  const [noData, setNoData] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (data) {
      prefillData();
    }
  }, [data]);

  const loadData = () => {
    setLoader(true);
    if (location?.state?.data) {
      setLoader(false);
      setData(location.state.data);
      setNoData(false);
      return;
    } else {
      return axios({
        method: "GET",
        url: `${backendUrl}/api/sites/?id=${id}`,
        withCredentials: true,
      })
        .then(async (res) => {
          setLoader(false);
          let dt = res?.data?.data[0];
          setData(dt);
          setNoData(false);
        })
        .catch(() => {
          setLoader(false);
          setNoData(true);
          setApiError("Something went wrong. Please try again");
        });
    }
  };

  const prefillData = () => {
    setLoader(true);
    setName(data.site_name);
    setLocationId(data.location_id);
    setHpio(data.gstin_number);
    setAbn(data.cin_number);
    setBackUrl(data.backend_url);
    setFrontUrl(data.frontend_url);
    setBusinessName(data.business_name);
    setAddressLine1(data.address_line_one);
    setAddressLine2(data.address_line_two);
    // setMimsIntegrated(data.mims_integrated);
    setCity(data.suburb);
    setphone(data.work_phone);
    setEmail(data.work_email);
    setPostcode(data.post_code);
    setState(data.state);
    setCountry(data.country);
    setFax(data.fax);
    setLoader(false);
    setDescription(data.description);
    let med = [];
    setIpAdmission(data.ip_admission)
    if (data?.is_allopathic) {
      med.push("Allopathic");
    }

    if (data?.is_ayurvedha) {
      med.push("Ayurvedha");
    }

    setMedicineType(med);
  };

  const nameHandler = (e) => {
    setNameError(null);
    setName(e.target.value);
  };

  const nameValidation = () => {
    if (!name) {
      setNameError("Required");
      return false;
    }
    return true;
  };

  const locationHandler = (e) => {
    setLocationError(null);
    setLocationId(e.target.value);
  };

  const locationValidation = (e) => {
    if (!name) {
      setLocationError("Required");
      return false;
    }
    return true;
  };

  const hpioHandler = (e) => {
    setHpioError(null);
    setHpio(e.target.value);
  };

  const hpioValidation = (e) => {
    if (!name) {
      setHpioError("Required");
      return false;
    }
    return true;
  };

  const abnHandler = (e) => {
    setAbnError(null);
    setAbn(e.target.value);
  };

  const abnValidation = (e) => {
    if (!abn) {
      setAbnError("Required");
      return false;
    }
    return true;
  };

  const backUrlHandler = (e) => {
    setBackUrlError(null);
    setBackUrl(e.target.value);
  };

  const backUrlValidation = (e) => {
    if (!backUrl) {
      setBackUrlError("Required");
      return false;
    }
    return true;
  };

  const frontUrlHandler = (e) => {
    setFrontUrlError(null);
    setFrontUrl(e.target.value);
  };

  const frontUrlValidation = (e) => {
    return true;
  };

  const businessNameHandler = (e) => {
    setBusinessNameError(null);
    setBusinessName(e.target.value);
  };

  const businessNameValidation = () => {
    if (!businessName) {
      setBusinessNameError("Required");
      return false;
    }
    return true;
  };

  const addressLine1Handler = (e) => {
    setAddressOneError(null);
    setAddressLine1(e.target.value);
  };

  const addressOneValidation = () => {
    if (!addressLine1) {
      setAddressOneError("Required");
      return false;
    }
    return true;
  };

  const addressLine2Handler = (e) => {
    setAddressLine2(e.target.value);
  };

  const suburbHandler = (e) => {
    setCityError(null);
    setCity(e.target.value);
  };

  const suburbValidation = () => {
    if (!suburb) {
      setCityError("Required");
      return false;
    }
    return true;
  };

  function isValidInput(input) {
    // Regular expression pattern to match allowed characters: 0-9, +, and white space
    const pattern = /^[0-9+\s]+$/;

    // Test if the input matches the pattern
    if (!input) {
      return true;
    }
    return pattern.test(input);
  }

  const phoneHandler = (e) => {
    setphoneError(null);
    let isValid = isValidInput(e.target.value);
    if (!isValid) {
      return;
    }
    setphone(e.target.value);
  };

  const phoneValidation = () => {
    if (!phone) {
      setphoneError("Required");
      return false;
    }
    return true;
  };

  const emailHandler = (e) => {
    setEmailError(null);
    setEmail(e.target.value);
  };

  const emailValidation = () => {
    if (!email) {
      setEmailError("Required");
      return false;
    }
    if (!isEmail(email) && email && email.length > 0) {
      setEmailError("Invalid email address");
      return false;
    }
    return true;
  };

  const isEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const postcodeHandler = (e) => {
    setPostcodeError(null);
    setPostcode(e.target.value);
  };

  const postCodeValidation = () => {
    if (!postcode) {
      setPostcodeError("Required");
      return false;
    }
    if (postcode && postcode.length < 4) {
      setPostcodeError("Postcode should be 4 digits");
      return false;
    }
    return true;
  };

  const stateHandler = (e) => {
    setStateError(null);
    setState(e.target.value);
  };

  const stateValidation = () => {
    if (!state) {
      setStateError("Required");
      return false;
    }
    return true;
  };

  const countryHandler = (e) => {
    setCountryError(null);
    setCountry(e.target.value);
  };

  const countryValidation = () => {
    if (!country) {
      setCountryError("Required");
      return false;
    }
    return true;
  };

  const faxHandler = (e) => {
    setFaxError(null);
    setFax(e.target.value);
  };

  const faxValidation = () => {
    if (!fax) {
      setFaxError("Required");
      return false;
    }
    return true;
  };

  const bannerHandler = (img) => {
    setBanner(img);
  };

  async function postData() {
    const formData = new FormData();
    formData.append("org_id", data.org_id);
    formData.append("site_name", name);
    // formData.append("location_id", location);
    formData.append("gstin_number", hpio);
    formData.append("backend_url", backUrl);
    formData.append("frontend_url", frontUrl);
    formData.append("cin_number", abn);
    formData.append("business_name", businessName);
    formData.append("address_line_one", addressLine1);
    formData.append("address_line_two", addressLine2);
    // formData.append("mims_integrated", mimsIntegrated);
    formData.append("suburb", suburb);
    formData.append("work_phone", phone);
    formData.append("work_email", email);
    formData.append("post_code", postcode);
    formData.append("state", state);
    formData.append("fax", fax);
    formData.append("country", country);
    formData.append("folder_name", `${name}/logo`);
    formData.append("is_allopathic", medicineType?.includes("Allopathic"));
    formData.append("is_ayurvedha", medicineType?.includes("Ayurvedha"));
    formData.append("ip_admission", ipAdmission);

    if (headerLogo) {
      formData.append("header_logo", headerLogo);
    }

    if (templateLogo) {
      formData.append("template_logo", templateLogo);
    }

    if (banner) {
      formData.append("banner", banner);
    }

    if (headerDeleted) {
      formData.append("header_deleted", headerDeleted);
    }

    if (templateDeleted) {
      formData.append("template_deleted", templateDeleted);
    }

    if (bannerDeleted) {
      formData.append("banner_deleted", bannerDeleted);
    }

    const result = await axios
      .patch(
        `${backendUrl}/api/site/${data.id}`,
        formData,
        { withCredentials: true },
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((res) => {
        setLoader(false);
        navigate(`/app/site/view/${data.id}`, {
          state: {
            showAlert: "Site details has been updated successfully",
          },
        });
      })
      .catch((e) => {
        setLoader(false);
        setError("Something went wrong. Please try again", true);
      });

    setimageURL(result.imagePath);

    return result.data;
  }

  const headerLogoHandler = (event) => {
    setHeaderLogoError("");
    let img = new Image();
    img.src = window.URL.createObjectURL(event.target.files[0]);

    const logo = event.target.files[0];
    setHeaderLogo(logo);
  };

  const templateLogoHandler = (event) => {
    setTemplateLogoError("");
    let img = new Image();
    img.src = window.URL.createObjectURL(event.target.files[0]);

    const logo = event.target.files[0];
    setTemplateLogo(logo);
  };

  const fileUploadValidation = (event, file) => {
    // var allowedExtensions =/(\.jpg|\.jpeg|\.png)$/i;
    let file_size = event.target.files[0].size;

    if (file_size > 1020157) {
      if (file === "HEADER") {
        
        setHeaderLogoError("File size exceeds 1 MB");
      }
      if (file === "TEMPLATE") {
        
        setTemplateLogoError("File size exceeds 1 MB");
      }
    }
  };

  const submitValidation = () => {
    let proceed = true;

    proceed = proceed && nameValidation();
    // proceed = proceed && locationValidation();
    proceed = proceed && backUrlValidation();
    proceed = proceed && frontUrlValidation();
    proceed = proceed && businessNameValidation();
    proceed = proceed && addressOneValidation();
    proceed = proceed && suburbValidation();
    proceed = proceed && phoneValidation();
    proceed = proceed && emailValidation();
    proceed = proceed && postCodeValidation();
    proceed = proceed && stateValidation();
    proceed = proceed && countryValidation();
    proceed = proceed && medicineTypeValidation();

    if (proceed) {
      // All validations passed, proceed with submitting the form
      return true;
    } else {
      // Some validations failed, display error messages
      nameValidation();
      // locationValidation();
      backUrlValidation();
      frontUrlValidation();
      businessNameValidation();
      addressOneValidation();
      suburbValidation();
      phoneValidation();
      emailValidation();
      postCodeValidation();
      stateValidation();
      countryValidation();
      medicineTypeValidation();

      return false;
    }
  };

  const submitHandler = async (event) => {
    setLoader(true);
    event.preventDefault();

    if (
      data.location_id === locationId &&
      data.site_name === name &&
      data.gstin_number === hpio &&
      data.backend_url === backUrl &&
      data.frontend_url === frontUrl &&
      data.cin_number === abn &&
      data.business_name === businessName &&
      data.address_line_one === addressLine1 &&
      data.address_line_two === addressLine2 &&
      data.suburb === suburb &&
      data.work_phone === phone &&
      data.work_email === email &&
      data.post_code === postcode &&
      data.state === state &&
      data.country === country &&
      data.fax === fax &&
      data.is_allopathic === medicineType.includes("Allopathic") &&
      data.is_ayurvedha === medicineType.includes("Ayurvedha") &&
      data.ip_admission === ipAdmission &&
      !headerLogo &&
      !templateLogo &&
      !headerDeleted &&
      !templateDeleted &&
      !bannerDeleted &&
      !banner
    ) {
      setLoader(false);
      setError("You have made no changes!");
      return;
    }

    let isProceed = submitValidation();

    if (!isProceed) {
      setLoader(false);
      return false;
    }

    if (headerLogoError || templateLogoError) {
      setLoader(false);
      fileUploadValidation(null, "HEADER");
      fileUploadValidation(null, "TEMPLATE");
      return;
    }

    await postData();

    setLoader(false);
  };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "/app/sites-management",
      iconName: "bi:building",
      name: "Sites Management",
    },
  ];

  const handleDelete = (logo) => {
    setDeleteLogo(logo);
  };

  const confirmDelete = async () => {
    if (deleteLogo === "template") {
      setTemplateDeleted(true);
    } else if (deleteLogo === "header") {
      setHeaderDeleted(true);
    } else {
      setBannerDeleted(true);
    }
    closeDelete();
  };

  const closeDelete = () => {
    setDeleteLogo();
  };

  const clearHeaderInput = () => {
    setHeaderLogoError();
    setHeaderLogo();
  };
  const clearTemplateInput = () => {
    setTemplateLogoError();
    setTemplateLogo();
  };

  const medicineOptions = [
    {
      label: "Allopathic",
      value: "Allopathic",
    },
    {
      label: "Ayurvedha",
      value: "Ayurvedha",
    },
  ];

  const medicineTypeHandler = (e) => {
    setMedicineTypeError(null);
    let sts = e.target.value;
    let arr;
    if (medicineType) {
      arr = [...medicineType, sts];
    } else {
      arr = [sts];
    }
    if (medicineType?.includes(sts)) {
      arr = arr.filter((s) => s !== sts);
    }
    if (!arr.length) {
      setMedicineTypeError("Required");
    }
    setMedicineType(arr);
  };

  const medicineTypeValidation = () => {
    if (!medicineType?.length) {
      setMedicineTypeError("Required");
      return false;
    }
    return true;
  };

  const ipAdmissionHandler = (e) => {
    setIpAdmission((prev) => !prev);
  };

  const retry = () => {
    setApiError(false);
    setTimeout(() => {
      loadData();
    }, 100);
  };

  return (
    <ThemeProvider theme={Theme}>
      <Loader open={loader} />
      <Breadcrumb options={breadCrumbData} />
      <CautionAlert
        severity="error"
        open={deleteLogo}
        content={`Are you sure you want to delete ${deleteLogo} logo?`}
        onCloseActionB={confirmDelete}
        onCloseActionA={closeDelete}
        okText="Delete"
        cancelText="Cancel"
      />
      <CautionAlert
        severity="error"
        open={apiError}
        content={apiError}
        onCloseActionB={retry}
        onCloseActionA={() => setApiError(null)}
        okText="Retry"
        cancelText="Dismiss"
      />
      <CautionAlert
        severity="error"
        open={error}
        content={error}
        onCloseActionB={() => setError(null)}
        okText="Ok"
        oneButton={true}
      />
      <CautionAlert
        severity="success"
        success={true}
        open={success}
        content={success}
        onCloseActionB={() => setSuccess(null)}
        okText="Ok"
        oneButton={true}
      />

      <PageHeader left="Edit Site" />
      <Loader open={loader} />

      <form>
        <Box className={classes.FormBox}>
          <Grid container direction="row" spacing={2}>
            {/* First Column */}
            <Grid
              container
              item
              xs={widthIs14 ? 12 : 6}
              direction="column"
              sx={{ maxWidth: widthIs14 ? "" : "590px !important" }}
              className={classes.Max}
            >
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Site Name"
                onChangeAction={nameHandler}
                onBlurAction={nameValidation}
                value={name}
                error={nameError}
                required
              />
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Location ID"
                onChangeAction={locationHandler}
                onBlurAction={locationValidation}
                error={locationError}
                value={locationId}
                required
                disabled
              />
              <TextBox
                xsLabel={4}
                xsInside={8}
                label="CIN"
                onChangeAction={abnHandler}
                value={abn}
              />

              <TextBox
                xsLabel={4}
                xsInside={8}
                label="GSTIN"
                onChangeAction={hpioHandler}
                value={hpio}
              />
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Business Name"
                onChangeAction={businessNameHandler}
                value={businessName}
                onBlurAction={businessNameValidation}
                error={businessNameError}
                placeholder="Business Name"
                required
              />

              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Address line 1"
                onChangeAction={addressLine1Handler}
                value={addressLine1}
                onBlurAction={addressOneValidation}
                error={addressOneError}
                placeholder="Address line 1"
                required
              />

              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Address line 2"
                onChangeAction={addressLine2Handler}
                value={addressLine2}
                placeholder="Address line 2"
              />

              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="City"
                onChangeAction={suburbHandler}
                value={suburb}
                onBlurAction={suburbValidation}
                error={suburbError}
                required
              />
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="State"
                onChangeAction={stateHandler}
                value={state}
                onBlurAction={stateValidation}
                error={stateError}
                placeholder="State"
                required
              />
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Country"
                onChangeAction={countryHandler}
                value={country}
                onBlurAction={countryValidation}
                error={countryError}
                placeholder="Country"
                required
              />

              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Post Code"
                onChangeAction={postcodeHandler}
                value={postcode}
                onBlurAction={postCodeValidation}
                error={postcodeError}
                placeholder="Post Code"
                required
              />

              {data?.banner && (
                <Grid container spacing={2} mb={1}>
                  <Grid item xs={4} className={`LabelBox`}></Grid>

                  <Grid item xs={4}>
                    <div
                      style={{
                        // background: "#56438E",
                        // padding: "10px",
                        height: "150px",
                        width: "100px",
                        // borderRadius: "15px",
                      }}
                    >
                      <Images image={data?.banner} />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ display: "flex", alignItems: "center", mb: 1 }}
                  >
                    <Button
                      startIcon={<Icon icon="fluent:delete-24-regular" />}
                      text="Delete"
                      onClickAction={() => handleDelete("banner")}
                      disabled={bannerDeleted}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2} mb={1}>
                <Grid item xs={4} className={`LabelBox`}>
                  Banner
                </Grid>
                <Grid item xs={8}>
                  <ImageEdit
                    image={banner}
                    dataHandler={bannerHandler}
                    ratio={41 / 59}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={widthIs14 ? 12 : 6}
              direction="column"
              className={classes.Max}
              sx={{
                marginLeft: widthIs1630 ? "" : "80px",
                maxWidth: widthIs14 ? "" : "590px !important",
              }}
            >
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Phone Number"
                onChangeAction={phoneHandler}
                value={phone}
                onBlurAction={phoneValidation}
                error={phoneError}
                placeholder="Phone"
                required
              />

              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Email"
                onChangeAction={emailHandler}
                value={email}
                onBlurAction={emailValidation}
                error={emailError}
                placeholder="Email"
                required
              />

              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Fax Number"
                onChangeAction={faxHandler}
                value={fax}
              />
              <CheckBoxes
                onChangeAction={(e) => medicineTypeHandler(e)}
                data={medicineOptions}
                label="Medicine Type"
                status={medicineType}
                error={medicineTypeError}
                required
              />
              <Fragment>
                <Grid container spacing={2}>
                  <Grid item xs={4} className={`LabelBox`}></Grid>
                  <Grid item xs={8}>
                    <FormGroup>
                      <FormControlLabel
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: "14px",
                          },
                        }}
                        control={
                          <Checkbox
                            style={{
                              color: "#615586",
                            }}
                            checked={ipAdmission === true}
                            value={ipAdmission === true}
                            onChange={ipAdmissionHandler}
                          />
                        }
                        label={"IP Admission"}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Fragment>

              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Backend URL"
                onChangeAction={backUrlHandler}
                value={backUrl}
                onBlurAction={backUrlValidation}
                error={backUrlError}
                required
              />

              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Frontend URL"
                onChangeAction={frontUrlHandler}
                value={frontUrl}
                onBlurAction={frontUrlValidation}
                error={frontUrlError}
              />

              {data?.header_logo && (
                <Grid container spacing={2} mb={1}>
                  <Grid item xs={4} className={`LabelBox`}></Grid>

                  <Grid item xs={4}>
                    <div
                      style={{
                        background: "#56438E",
                        padding: "10px",
                        height: "100px",
                        width: "150px",
                        borderRadius: "15px",
                      }}
                    >
                      <Images image={data?.header_logo} />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ display: "flex", alignItems: "center", mb: 1 }}
                  >
                    <Button
                      startIcon={<Icon icon="fluent:delete-24-regular" />}
                      text="Delete"
                      onClickAction={() => handleDelete("header")}
                      disabled={headerDeleted}
                    />
                  </Grid>
                </Grid>
              )}

              <UploadButton
                xsLabel={4}
                xsInside={8}
                label="Header Logo"
                onBlurAction={(e) => fileUploadValidation(e, "HEADER")}
                onChangeAction={headerLogoHandler}
                error={headerLogoError}
                clear={clearHeaderInput}
              />
              {data?.template_logo && (
                <Grid container spacing={2} mb={1}>
                  <Grid item xs={4} className={`LabelBox`}></Grid>

                  <Grid item xs={4}>
                    <Images image={data?.template_logo} />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ display: "flex", alignItems: "center", mb: 1 }}
                  >
                    <Button
                      startIcon={<Icon icon="fluent:delete-24-regular" />}
                      text="Delete"
                      onClickAction={() => handleDelete("template")}
                      disabled={templateDeleted}
                    />
                  </Grid>
                </Grid>
              )}

              <UploadButton
                xsLabel={4}
                xsInside={8}
                label="Template Logo"
                onBlurAction={(e) => fileUploadValidation(e, "TEMPLATE")}
                onChangeAction={templateLogoHandler}
                error={templateLogoError}
                clear={clearTemplateInput}
                logo={true}
              />

              {/* {images.map((image) => (
                    <div key={image}>
                      <img
                        src={"http://axon.medipapel.com:8080/" + image}
                        alt="img"
                      ></img>
                    </div>
                  ))} */}
            </Grid>
            {/* First Column */}
          </Grid>
        </Box>
        <PageFooter
          right={
            <Fragment>
              <Box
                sx={{
                  px: 2,
                }}
              >
                <Button
                  text="Update"
                  // type="submit"
                  onClickAction={submitHandler}
                />
              </Box>

              <Box>
                <Button
                  fontSize="14px"
                  onMouseDownAction={() => navigate(-1)}
                  bgLight={true}
                  text="Cancel"
                />
              </Box>
            </Fragment>
          }
        />
      </form>
    </ThemeProvider>
  );
}
