let subscriptionColumns = [
    {
        dataField: "id",
        text: "id",
        hidden: true,
      },
      {
        dataField: "site",
        text: "Site Name",
        formatter: (value) => {
          return value?.site_name;
        },
      },
      {
        dataField: "site",
        text: "Business Name",
        formatter: (value) => {
          return value?.business_name;
        },
      },
      {
        text: "Credit",
        dataField: "credit",
      },
      {
        text: "Status",
        dataField: "status",
      },
      {
        text: "Actions",
        dataField: "action",
      },
];

const fNames = {
    subscriptionColumns
}

export default fNames;
