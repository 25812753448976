import moment from "moment";
import timestampHandler from "../utils/timstampHandler";

const viewSiteDetailsColumns = [
  {
    title: "Site Name",
    field: "site_name",
  },
  {
    title: "Organisation Name",
    field: "organisation",
    formatter:(value)=>{
      return value.organisation_name
    }
  },
  {
    title: "Location ID",
    field: "location_id",
  },
  {
    title: "Business Name",
    field: "business_name",
  },
  {
    title: "CIN",
    field: "cin_number",
    formatter:(value) =>{
      return value ? value : "N/A"
    }
  },
  {
    title: "GSTIN",
    field: "gstin_number",
    formatter:(value) =>{
      return value ? value : "N/A"
    }
  },
  // {
  //   title: "MIMS Integrated",
  //   field: "mims_integrated",
  //   formatter: (value) => {
  //     return value ? "Yes" : "No";
  //   },
  // },
  {
    title: "Backend URL",
    field: "backend_url",
  },
  {
    title: "Frontend URL",
    field: "frontend_url",
  },
  {
    title: "Address Line 1",
    field: "address_line_one",
  },
  {
    title: "Address Line 2",
    field: "address_line_two",
    formatter:(value) =>{
      return value ? value : "N/A"
    }
  },
  {
    title: "City",
    field: "suburb",
  },
  {
    title: "State",
    field: "state",
  },
  {
    title: "Postcode",
    field: "post_code",
  },
  {
    title: "Country",
    field: "country",
  },
  {
    title: "Phone Number",
    field: "work_phone",
  },
  {
    title: "Fax Number",
    field: "fax",
    formatter:(value) =>{
      return value ? value : "N/A"
    }
  },
  {
    title: "Email",
    field: "work_email",
  },
  {
    title: "Medicine Types",
    field: "",
    formatter: (v, r) => {
      let med = []
      if(r.is_allopathic){
        med.push("Allopathic")
      }
      if(r.is_ayurvedha){
        med.push("Ayurvedha")
      }

      return med.join(", ")

    }
  },
  {
    title: "IP Admission",
    field: "ip_admission",
    formatter: (value) => {
      if(value){
        return 'Yes'
      }else{
        return "No"
      }
    }
  },
  {
    title: "Created by",
    field: "created_by_uid",
  },
  {
    title: "Created at",
    field: "created_at",
    formatter: (value) => {
      return timestampHandler(value, "DD/MM/YYYY");
    },
  },
  {
    title: "Updated at",
    field: "updated_at",
    formatter: (value) => {
      return timestampHandler(value, "DD/MM/YYYY");
    },
  },
  {
    title: "Updated by",
    field: "updated",
    formatter: (value) => {
      return `${value?.name}`;
    },
  },
  {
    title: "Header Logo",
    field: "header_logo",
    noLabel: true,
    formatter: (row, value) => {
      return (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <strong>Header Logo:&nbsp;</strong>

            {row ? (
              <>
                <div
                  style={{
                    background: "#56438E",
                    padding: "10px",
                    height: "100px",
                    width: "150px",
                    display: "inline-block",
                    borderRadius: "15px",
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${row})`,
                      height: "100%",
                      width: "100%",
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                </div>
              </>
            ) : (
              "Not uploaded"
            )}
          </div>
        </>
      );
    },
  },
  {
    title: "Template Logo",
    field: "template_logo",
    noLabel: true,
    formatter: (row, value) => {
      return (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <strong>Template Logo:&nbsp;</strong>
            {row ? (
              <div
                style={{
                  backgroundImage: `url(${row})`,
                  height: "100px",
                  width: "150px",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  display: "inline-block",
                }}
              ></div>
            ) : (
              "Not uploaded"
            )}
          </div>
        </>
      );
    },
  },

  {
    title: "Banner",
    field: "banner",
    noLabel: true,
    formatter: (row, value) => {
      return (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <strong>Banner:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>
            {row ? (
              <div
                style={{
                  backgroundImage: `url(${row})`,
                  height: "150px",
                  width: "100px",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  display: "inline-block",
                }}
              ></div>
            ) : (
              "Not uploaded"
            )}
          </div>
        </>
      );
    },
  },
];
const siteDetailsTableColumns = [
  {
    dataField: "id",
    text: "id",
    hidden: true,
  },
  {
    dataField: "name",
    text: "First Name",
  },
  {
    dataField: "last_name",
    text: "Last Name",
  },
  {
    dataField: "username",
    text: "Username",
  },
  {
    dataField: "role_name",
    text: "User Role",
  },
  {
    dataField: "email",
    text: "Email",
  },
  {
    dataField: "mobile",
    text: "Mobile Number",
  },
  {
    dataField: "status",
    text: "Status",
  },
  {
    dataField: "action",
    text: "Action",
  },
];

const siteDetails = { viewSiteDetailsColumns, siteDetailsTableColumns };

export default siteDetails;
