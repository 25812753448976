import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";
import classes from "./CheckBoxes.module.css";

function CheckBoxes(props) {
  return (
    <Fragment>
      <Grid container spacing={2}>
        {props.noLabel ? (
          ""
        ) : (
          <Grid item xs={props.xsLabel || 4} className={`LabelBox`}>
            <label>
              {props.label}
              {props.required ? <sup>*</sup> : null}
            </label>
          </Grid>
        )}

        <Grid
          item
          xs={8}
          sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {props.data.map((item) => (
            <Grid item xs={4}>
              <FormGroup>
                <FormControlLabel
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "14px",
                      // whiteSpace: "nowrap"
                    },
                  }}
                  control={
                    <Checkbox
                      defaultChecked={
                        props.status && props.status.includes(item.label)
                      }
                      disabled={props.disabled || item.disabled}
                      style={{
                        color:
                          props.disabled || item.disabled ? null : "#615586",
                      }}
                      checked={
                        props.status && props.status.includes(item.label)
                      }
                      value={item.value}
                      id={item.value}
                      onChange={props.onChangeAction}
                    />
                  }
                  label={item.label}
                />
              </FormGroup>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {props.error ? (
        <Typography
          variant="caption"
          sx={{ paddingLeft: "34% !important" }}
          className={classes.ErrorMsg}
        >
          {props.error}
        </Typography>
      ) : null}
    </Fragment>
  );
}

export default CheckBoxes;
