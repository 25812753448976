import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { ThemeProvider } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import MuiTable from "../../UI/MuiTable/MuiTable";
import Button from "../../UI/Button/Button";
import { Alert, Box, Grid, Typography, useMediaQuery } from "@mui/material";
import classes from "./RolesManagement.module.css";
import TextBox from "../../UI/FormGroup/TextBox/TextBox";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    minWidth: "40%",
    borderRadius: "0px",
    maxHeight: "90vh",
  },

  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
    borderRadius: 0,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    borderRadius: 0,
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        textAlign: "center",
        backgroundColor: "#615586",
        color: "white",
      }}
      {...other}
    >
      {children}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddFeaturePopup = ({ data, open, closeHandler, saveFeatureHandler }) => {
  const widthIs14 = useMediaQuery("(max-width:1279px)");
  const widthIs1630 = useMediaQuery("(max-width:1630px)");

  const [feature, setFeature] = useState(null);
  const [featureError, setFeatureError] = useState(null);

  const featureHandler = (e) => {
    setFeatureError(null);
    setFeature(e.target.value);
  };

  const featureValidation = () => {
    if (!feature) {
      setFeatureError("Required");
      return false;
    }
    return true;
  };

  const saveHandler = () => {
    let valid = featureValidation();
    if (!valid) {
      return null;
    }
    saveFeatureHandler(feature);
  };

  return (
    <ThemeProvider>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          style={{ textAlign: "left" }}
        >
          Add Feature
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form>
            <Box className={classes.FormBox}>
              <Grid container direction="row" spacing={2}>
                {/* First Column */}
                <Grid
                  container
                  item
                  xs={12}
                  direction="column"
                  sx={{ maxWidth: widthIs14 ? "" : "590px !important" }}
                  className={classes.Max}
                >
                  <TextBox
                    xsLabel={4}
                    xsInside={8}
                    type="text"
                    label="Feature Name"
                    onChangeAction={featureHandler}
                    onBlurAction={featureValidation}
                    value={feature}
                    error={featureError}
                    placeholder="State"
                    required
                  />
                </Grid>
              </Grid>
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button text="Save" onMouseDownAction={saveHandler} />
          <Button text="Close" onMouseDownAction={closeHandler} />
        </DialogActions>
      </BootstrapDialog>
    </ThemeProvider>
  );
};

export default AddFeaturePopup;
