import {
  Alert,
  Box,
  Grid,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";
import PageFooter from "../../Layout/PageFooter/PageFooter";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import Theme from "../../Theme/Theme";
import Button from "../../UI/Button/Button";
import TextBox from "../../UI/FormGroup/TextBox/TextBox";
import Loader from "../../UI/Loader/Loader";
import classes from "./Subscription.module.css";
import UploadButton from "../../UI/FormGroup/UploadButton/UploadButton";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import storageService from "../../utils/localStorageHelpers";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import CautionAlert from "../../utils/CautionAlert";
import { useDispatch } from "react-redux";
import { saveSite } from "../../../redux/site";
import CryptoJS from "crypto-js";
import CustomRadioGroup from "../../UI/FormGroup/RadioGroup/RadioGroup";
import SearchSite from "../../UI/SearchSite/SearchSite";
import moment from "moment";

export default function EditSignSubscription() {
  const widthIs14 = useMediaQuery("(max-width:1279px)");
  const widthIs930 = useMediaQuery("(max-width:930px)");
  const widthIs1630 = useMediaQuery("(max-width:1630px)");
  const location = useLocation();
  const { id } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [success, setSuccess] = useState(null);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const [apiError, setApiError] = useState(null);

  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);
  // const [key, setKey] = useState(null);
  // const [keyError, setKeyError] = useState(null);
  const [site, setSite] = useState(null);
  const [siteError, setSiteError] = useState(null);
  const [credit, setCredit] = useState(null);
  const [creditError, setCreditError] = useState(null);
  // const [userId, setUserId] = useState(null);
  // const [userIdError, setUserIdError] = useState(null);
  const [data, setData] = useState(null);
  const [noData, setNoData] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    prefillData();
  }, [data]);

  const loadData = async () => {
    setLoader(true);
    if (location.state && location.state.data) {
      setData(location.state.data);
      setNoData(false);
      setLoader(false);
    } else {
      await axios({
        method: "GET",
        withCredentials: true,
        url: `${backendUrl}/api/site/subscriptions?id=${id}`,
      })
        .then(async (gb) => {
          setLoader(false);
          const global = gb.data && gb.data.data?.[0];
          if (!global) {
            throw new Error("Subscription not found");
          }

          setData(global);
          setNoData(false);
        })
        .catch((e) => {
          
          setNoData(true);
          setApiError("Something went wrong. Please try again");
          setLoader(false);
        });
    }
  };

  const prefillData = () => {
    if (data) {
      setSite(data.site)
      setCredit(data.credit);
    }
  };

  const emailHandler = (e) => {
    setEmailError(null);
    setEmail(e.target.value);
  };

  const emailValidation = () => {
    // if (!email) {
    //   setEmailError("Required");
    //   return false;
    // }
    if (email && (!isEmail(email) && email && email.length > 0)) {
      setEmailError("Invalid email address");
      return false;
    }
    return true;
  };

  const isEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  // const userIdHandler = (e) => {
  //   setUserIdError(null);
  //   setUserId(e.target.value);
  // };

  // const userIdValidation = (e) => {
  //   if (!userId) {
  //     setUserIdError("Required");
  //     return false;
  //   }
  //   return true;
  // };

  // const keyHandler = (e) => {
  //   setKey(e.target.value);
  // };

  // const keyValidation = () => {
  //   return true;
  // };
  const siteValidation = (msg) => {
    if (msg) {
      setSiteError(msg);
      return false;
    }
    if (!site) {
      setSiteError("Required");
      return false;
    }
    return true;
  };

  const selectedSite = (value) => {
    setSite(value);
  };

  const creditHandler = (e) => {
    setCreditError(null);
    setCredit(e.target.value);
  };

  const creditValidation = (e) => {
    if (!credit) {
      setCreditError("Required");
      return false;
    }
    return true;
  };

  async function postData() {
    let body = {
      site_id: site.id,
      credit,
    };

    const result = await axios
      .patch(
        `${backendUrl}/api/subscription/${id}`,
        body,
        { withCredentials: true },
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((res) => {
        setLoader(false);
        navigate(`/app/e-sign-subscription`, {
          state: {
            showAlert: "E-sign subscription has been successfully updated",
          },
        });
      })
      .catch((e) => {
        setLoader(false);
        if (e.response?.data?.message?.includes("404")) {
          setError("Email/Key you entered is incorrect");
          return;
        }
        setError("Something went wrong. Please try again", true);
      });

    return result.data;
  }

  const submitValidation = () => {
    let proceed = true;
    // proceed = proceed && keyValidation();
    proceed = proceed && siteValidation();
    proceed = proceed && creditValidation();

    if (proceed) {
      // All validations passed, proceed with submitting the form
      return true;
    } else {
      // Some validations failed, display error messages
      // keyValidation();
      siteValidation();
      creditValidation();

      return false;
    }
  };

  const submitHandler = async (event) => {
    setLoader(true);
    event.preventDefault();

    let isProceed = submitValidation();

    if (!isProceed) {
      setLoader(false);
      return false;
    }

    await postData();

    setLoader(false);
  };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    
    {
      link: "/app/e-sign-subscription",
      iconName: "mdi:sign",
      name: "Sign Subscriptions",
    },
  ];

  const retry = () => {
    setApiError(null);
    setTimeout(() => {
      loadData();
    }, 100);
  };

  return (
    <ThemeProvider theme={Theme}>
      <Loader open={loader} />
      <Breadcrumb options={breadCrumbData} />
      {apiError ? (
        <CautionAlert
          severity="error"
          open={apiError}
          content={apiError}
          onCloseActionB={() => setApiError()}
          onCloseActionA={retry}
          okText="Retry"
          cancelText="Dismiss"
        />
      ) : null}
      {error ? (
        <CautionAlert
          severity="error"
          open={error}
          content={error}
          onCloseActionB={() => setError(null)}
          okText="Ok"
          oneButton={true}
        />
      ) : null}

      {success ? (
        <CautionAlert
          severity="success"
          success={true}
          open={success}
          content={success}
          onCloseActionB={() => setSuccess(null)}
          okText="Ok"
          oneButton={true}
        />
      ) : null}

      <PageHeader left="Edit E-Sign Subscription" />
      <Loader open={loader} />

      {!noData && (
        <form>
          <Box className={classes.FormBox}>
            <Grid container direction="row" spacing={2}>
              {/* First Column */}
              <Grid
                container
                item
                xs={widthIs14 ? 12 : 6}
                direction="column"
                sx={{ maxWidth: widthIs14 ? "" : "590px !important" }}
                className={classes.Max}
              >
                <SearchSite
                  xsLabel={4}
                  xsInside={8}
                  siteValidation={siteValidation}
                  siteError={siteError}
                  selectedSite={selectedSite}
                  value={site
                  }
                />
                  {/* <TextBox
                    xsLabel={4}
                    xsInside={8}
                    type="text"
                    label="API Key"
                    onChangeAction={keyHandler}
                    value={key}
                    onBlurAction={keyValidation}
                    error={keyError}
                    placeholder="Key"
                    required
                  /> */}
              </Grid>

              <Grid
                container
                item
                xs={widthIs14 ? 12 : 6}
                direction="column"
                className={classes.Max}
                sx={{
                  marginLeft: widthIs1630 ? "" : "80px",
                  maxWidth: widthIs14 ? "" : "590px !important",
                }}
              >
                <TextBox
                  xsLabel={4}
                  xsInside={8}
                  type="text"
                  label="Credit"
                  onChangeAction={creditHandler}
                  value={credit}
                  onBlurAction={creditValidation}
                  error={creditError}
                  placeholder="Key"
                  required
                />
              </Grid>
            </Grid>
          </Box>
          <PageFooter
            right={
              <Fragment>
                <Box
                  sx={{
                    px: 2,
                  }}
                >
                  <Button
                    text="Submit"
                    // type="submit"
                    onClickAction={submitHandler}
                  />
                </Box>

                <Box>
                  <Button
                    fontSize="14px"
                    onMouseDownAction={() => navigate(-1)}
                    bgLight={true}
                    text="Cancel"
                  />
                </Box>
              </Fragment>
            }
          />
        </form>
      )}
    </ThemeProvider>
  );
}
