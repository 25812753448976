import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "../../UI/Button/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Loader from "../../UI/Loader/Loader";
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  useMediaQuery,
} from "@mui/material";
import classes from "./Fees.module.css";
import CautionAlert from "../../utils/CautionAlert";
import BootstrapTable from "react-bootstrap-table-next";
import styles from "../../Layout/DataTable/DataTable.module.css";
import TextInput from "../../UI/FormGroup/TextBox/TextInput";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import storageService from "../../utils/localStorageHelpers";
import TextAreaNonGrid from "../../UI/FormGroup/TextArea/TextAreaNonGrid";
import TextInputNonContainer from "../../UI/FormGroup/TextBox/TextInputNonContainer";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    maxWidth: "850px",
    borderRadius: "0px",
    width: "660px",
  },

  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
    borderRadius: 0,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    borderRadius: 0,
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        textAlign: "left",
        backgroundColor: "#615586",
        color: "white",
      }}
      {...other}
    >
      {children}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function PrivateFeePopup(props) {
  const widthIs14 = useMediaQuery("(max-width:1279px)");
  const [loader, setLoader] = useState(null);
  const [error, setError] = useState(null);
  const [popupError, setPopupError] = useState(false);
  const [providerAll, setProviderAll] = useState(true);

  const [privateFee, setPrivateFee] = useState(null);
  const [privateFeeError, setPrivateFeeError] = useState(null);
  const [gapFee, setGapFee] = useState(null);

  const [mbsItems, setMbsItems] = useState(null);
  const [mbsItemError, setMbsItemError] = useState(null);
  const [mbsListing, setMbsListing] = useState(null);
  const [searchMbsOptions, setSearchMbsOptions] = useState([]);
  const [mbsInput, setMbsInput] = useState(null);
  const [percent, setPercent] = useState(null);
  const [percentDisable, setPercentDisable] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [item, setItem] = useState(null);
  const [itemError, setItemError] = useState(null);
  const [description, setDescription] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [gstIncluded, setGstIncluded] = useState(false);
  const [gstPercentage, setgstPercentage] = useState(10);

  const errorFromPopupClose = () => {
    setPopupError(false);
  };

  const errorHandler = (msg, autoHidden) => {
    setError(msg);
  };

  const mbsData = [
    {
      mbs_item: " ",
      schedule_fee: " ",
      description: " ",
    },
  ];

  const viewUserColumns = [
    {
      text: "MBS Item",
      dataField: "item_num",
    },
    {
      text: "Schedule Fee",
      dataField: "schedule_fee",
    },
    {
      text: "Description",
      dataField: "description",
    },
  ];
  //styling row's background
  const rowClasses = (row, rowIndex) => {
    let classes2 = null;

    if (rowIndex % 2 === 0) {
      classes2 = classes.RowEven;
    } else {
      classes2 = classes.RowOdd;
    }
    // classes = classes + ' ' + styles.Row
    return classes2;
  };

  const mbsHandler = (event, value) => {
    setMbsItemError();
    setMbsItems(value);
    setMbsListing(value[0]);
    setMbsInput(null);
  };
  const mbsInputHandler = (e, value) => {
    if (e?.target) {
      setMbsInput(e.target.value);
    }
  };

  const mbsValidation = (event, value) => {
    if (value && value) {
      setMbsItems(value);
    }
    if (!mbsItems && !newItem) {
      setMbsItemError("Required");
    }
  };

  const itemHandler = (e) => {
    setItemError();
    setItem(e.target.value);
  };
  const itemValidation = () => {
    if (newItem && !item) {
      setItemError("Required");
    }
  };

  const descriptionHandler = (e) => {
    setDescriptionError();
    setDescription(e.target.value);
  };

  const descriptionValidation = () => {
    if (newItem && !description) {
      setDescriptionError("Required");
    }
  };

  useEffect(() => {
    let apiTimeOut;
    if (mbsInput?.length >= 1) {
      apiTimeOut = setTimeout(() => {
        searchMbsItem();
      }, 1000);
    } else {
      // setSetPatientSearched([]);
    }
    return () => {
      clearTimeout(apiTimeOut);
    };
  }, [mbsInput]);

  const searchMbsItem = async () => {
    let siteDt = storageService.loadSite();
    const controller = new AbortController();
    await axios({
      method: "GET",
      url: `${backendUrl}/api/mbs_item?item_num=${mbsInput}`,
      signal: controller.signal,
      withCredentials: true,
    })
      .then(async (res) => {
        let dt = res.data.data;
        setSearchMbsOptions(dt);
      })
      .catch((e) => {
        
        setMbsItemError("Something went wrong. Please try again");
      });
  };

  const handlePrivateFee = (e) => {
    setPrivateFeeError("");
    let value = e.target.value;
    setPrivateFee(e.target.value);
    setPercent("");
    setPercentDisable(true);

    if (mbsItems) {
      let Sfee = mbsItems.schedule_fee;
      setGapFee((Number(value) - Number(Sfee)).toFixed(2));
    }
    if (value.length === 0) {
      setGapFee("");
      setPercentDisable(false);
    }
  };

  const privateFeeValidation = () => {
    if (!privateFee) {
      setPrivateFeeError("Required");
    }
  };

  const handlePrivateFeePercent = (e) => {
    setPrivateFeeError("");
    let value = e.target.value;
    setPercent(e.target.value);

    if (mbsItems) {
      let Sfee = mbsItems.schedule_fee;
      let percentValue = ((Number(value) / 100) * Number(Sfee)).toFixed(2);
      setGapFee(percentValue);
      setPrivateFee((Number(percentValue) + Number(Sfee)).toFixed(2));
    }

    if (value.length === 0) {
      setPrivateFee("");
      setGapFee("");
    }
  };

  const gstHandler = () => {
    setGstIncluded((prev) => !prev);
  };

  /*
   *
   *
   *     THE BELOW CODE IS FOR PROVIDER SEARCH
   *
   *
   */

  const [patientError, setPatientError] = useState(null);
  const [patients, setPatients] = useState([]);
  const [patientsListing, setPatientsListing] = useState(null);
  const [sendToAll, setSendToAll] = useState(false);
  const [searchPatientOptions, setSearchPatientOptions] = useState([]);
  const [patientInput, setPatientInput] = useState(null);
  const [inlineError, setInlineError] = useState(null);

  useEffect(() => {
    let apiTimeOut;
    if (patientInput?.length >= 3) {
      apiTimeOut = setTimeout(() => {
        searchSinglePatient();
      }, 1000);
    } else {
      // setSetPatientSearched([]);
    }
    return () => {
      clearTimeout(apiTimeOut);
    };
  }, [patientInput]);

  const searchSinglePatient = async () => {
    let siteDt = storageService.loadSite();
    // const controller = new AbortController();
    await axios({
      method: "GET",
      url: `${backendUrl}/${siteDt[0].site_name}/api/site_user/search?provider=true&first_name=${patientInput}`,
      // signal: controller.signal,
      withCredentials: true,
    })
      .then(async (res) => {
        let dt = res.data.data;
        setSearchPatientOptions(dt);
      })
      .catch((e) => {
        
        setInlineError("Something went wrong. Please try again");
      });
  };

  const patientHandler = (event, value) => {
    setPatientError();
    setPatients(value);
    setPatientsListing(value[0]);
    setPatientInput(null);
  };

  const patientInputHandler = (e, value) => {
    setInlineError();
    if (e?.target) {
      setPatientInput(e.target.value);
    }
  };

  const patientsValidation = (event, value) => {
    if (value && value) {
      setPatientError(value);
    }
    if (!providerAll && patients.length === 0) {
      setPatientError("Required");
    }
  };

  /*
   *
   *     THE ABOVE CODE IS FOR PROVIDER SEARCH
   *
   */

  const submitValidation = () => {
    itemValidation();
    mbsValidation();
    patientsValidation();
    privateFeeValidation();
    descriptionValidation();
  };

  const submitHandler = async () => {
    let siteDt = storageService.loadSite();
    setLoader(true);
    if (
      (!mbsItems && !item) ||
      (newItem && !description) ||
      (!providerAll && patients.length === 0) ||
      !privateFee
    ) {
      submitValidation();
      setLoader(false);
      return;
    } else {
      await axios({
        method: "POST",
        url: `${backendUrl}/${siteDt[0].site_name}/api/private_fees`,
        data: {
          mbs_item: newItem ? item : mbsItems?.item_num,
          schedule_fee: newItem ? null : mbsItems?.schedule_fee,
          private_fee: privateFee,
          description: newItem ? description : mbsItems?.description,
          gap_fee: newItem ? null : String(gapFee),
          all_provider: providerAll,
          gst_included: gstIncluded,
          gst_percentage: gstPercentage,
          provider_ids: patients?.map((e) => e.id),
        },
        withCredentials: true,
      })
        .then((res) => {
          setLoader(false);
          props.handlePopupClose();
          props.success(true);
          // navigate(`/app/user`, {
          //   state: { showAlert: "User has been successfully added" },
          // });
        })
        .catch((e) => {
          setLoader(false);
          
          if (
            e?.response &&
            e?.response?.data?.message.includes("already added")
          ) {
            setPopupError(
              "A record exist for the same item number. You can add only one record for the same item number"
            );
          } else {
            setPopupError("Something went wrong. Please try again");
          }
        });
    }
  };

  const allProviderHandler = (e, newValue) => {
    setPatientError();
    setPatients([]);
    setSearchPatientOptions([]);
    setPatientInput(null);
    setProviderAll(newValue);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={props.handlePopupClose}
        aria-labelledby="customized-dialog-title"
        open={props.PopUpOpen}
      >
        <Loader open={loader} />

        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.handlePopupClose}
        >
          {props.title}
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <CautionAlert
            oneButton={true}
            severity="error"
            open={popupError}
            content={popupError}
            onCloseActionB={errorFromPopupClose}
            okText="Ok"
          />

          {error ? (
            <>
              <Alert
                severity="error"
                sx={{
                  mb: 2,
                  border: "1px solid red",
                  zIndex: "99999 !important",
                  position: "relative",
                }}
              >
                {error}
              </Alert>
              <Backdrop
                sx={{ backgroundColor: "#00000000", zIndex: 500 }}
                open={error}
              ></Backdrop>
            </>
          ) : null}

          <form>
            <Box className={classes.FormBox}>
              <Grid container direction="row" spacing={2}>
                <Grid
                  container
                  item
                  xs={12}
                  direction="column"
                  sx={{ maxWidth: widthIs14 ? "" : "590px !important" }}
                  className={classes.Max}
                >
                  <Grid item xs={2.4}>
                    <Fragment>
                      <Grid container spacing={2} mb={1}>
                        <Grid item xs={4} className={`LabelBox`}>
                          <label>
                            Search MBS item <sup>*</sup>
                          </label>
                        </Grid>
                        <Grid item xs={8}>
                          <div>
                            <Autocomplete
                              value={mbsItems && mbsItems}
                              id="combo-box-demo"
                              options={searchMbsOptions && searchMbsOptions}
                              onChange={mbsHandler}
                              onInputChange={mbsInputHandler}
                              // onBlur={mbsValidation}
                              noOptionsText={
                                searchMbsOptions.length <= 0
                                  ? "Not Found"
                                  : "Searching..."
                              }
                              open={mbsInput}
                              onClose={() => setMbsInput()}
                              disabled={newItem}
                              disableCloseOnSelect
                              disableClearable
                              variant="outlined"
                              ListboxProps={{
                                style: { maxHeight: "15rem" },
                                position: "bottom-start",
                              }}
                              getOptionLabel={(option) => option.item_num}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>{option.item_num}</li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  ListboxProps={{
                                    style: { maxHeight: "15rem" },
                                    position: "bottom-start",
                                  }}
                                  sx={{
                                    "& .MuiInputBase-input": {
                                      height: "1.5rem !important",
                                    },
                                    border: "0px !important",
                                    "& .MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
                                      {
                                        border: "0px !important",
                                      },
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "#ff000000 !important",
                                      },
                                    "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "#ff000000 !important",
                                      },
                                  }}
                                  {...params}
                                />
                              )}
                              sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#00000000 !important",
                                },
                                "& .MuiInputBase-input": {
                                  height: "1.5rem !important",
                                },
                                ".MuiAutocomplete-endAdornment": {
                                  display: "none !important",
                                },
                                "& .MuiOutlinedInput-root": {
                                  padding: "2px !important",
                                  borderRadius: "0px",
                                  backgroundColor: sendToAll
                                    ? "#e9e9e9 !important"
                                    : "white",
                                  border: mbsItemError
                                    ? "1.5px solid #FF0000 !important"
                                    : "1px solid #E8E8E8",
                                  height:
                                    mbsItems && mbsItems.length > 2
                                      ? "150px !important"
                                      : "",
                                  overflow: "scroll",
                                },
                                "& .Mui-disabled .MuiOutlinedInput-notchedOutline":
                                  {
                                    backgroundColor: "#e9e9e9 !important",
                                    border: "0px !important",
                                  },
                              }}
                            />
                          </div>

                          {mbsItemError ? (
                            <label className={classes.ErrorMsg}>
                              {mbsItemError}
                            </label>
                          ) : null}
                        </Grid>

                        <Grid item xs={4}></Grid>
                        <Grid item xs={8}>
                          <div style={{ marginTop: "-20px" }}>
                            <FormControlLabel
                              sx={{
                                "& .MuiTypography-root": {
                                  fontSize: "14px",
                                },
                              }}
                              label="Add new item"
                              control={
                                <Checkbox
                                  style={{
                                    color: "#615586",
                                  }}
                                  checked={newItem}
                                  onChange={() => {
                                    setMbsItemError();
                                    setNewItem((prev) => !prev);
                                  }}
                                />
                              }
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Fragment>

                    {newItem && (
                      <TextInput
                        xsLabel={4}
                        xsInside={4}
                        type="text"
                        label="Item"
                        onChangeAction={itemHandler}
                        numberOnly
                        onBlurAction={itemValidation}
                        value={item}
                        error={itemError}
                        placeholder="Home Phone Number"
                        required
                      />
                    )}

                    {!newItem && (
                      <BootstrapTable
                        keyField="id"
                        data={mbsItems ? [mbsItems] : mbsData}
                        columns={viewUserColumns}
                        classes={styles.Table}
                        headerClasses={classes.Header}
                        rowClasses={rowClasses}
                        remote={true}
                        fetchInfo={100}
                      />
                    )}
                    <div style={{ marginTop: newItem ? "0" : "15px" }}>
                      <Grid container spacing={2}>
                        <TextInputNonContainer
                          xsLabel={4}
                          xsInside={4}
                          type="number"
                          label="Fees"
                          onChangeAction={handlePrivateFee}
                          numberOnly
                          onBlurAction={privateFeeValidation}
                          value={privateFee}
                          error={privateFeeError}
                          placeholder="Home Phone Number"
                          required={newItem ? true : false}
                          disabled={
                            !newItem && (!mbsItems || percent) ? true : false
                          }
                        />
                        {newItem && (
                          <Fragment>
                            <Grid item xs={4}>
                              <FormGroup>
                                <FormControlLabel
                                  sx={{
                                    "& .MuiTypography-root": {
                                      fontSize: "14px",
                                    },
                                  }}
                                  control={
                                    <Checkbox
                                      style={{
                                        color: "#615586",
                                      }}
                                      value={gstIncluded}
                                      onChange={gstHandler}
                                      checked={gstIncluded}
                                    />
                                  }
                                  label="GST included"
                                />
                              </FormGroup>
                            </Grid>
                          </Fragment>
                        )}
                      </Grid>
                    </div>

                    {!newItem && (
                      <>
                        <TextInput
                          xsLabel={4}
                          xsInside={4}
                          type="number"
                          label="Fees ( % )"
                          onChangeAction={handlePrivateFeePercent}
                          numberOnly
                          onBlurAction={privateFeeValidation}
                          value={percent}
                          // error={homeNumberError}
                          placeholder="Home Phone Number"
                          // required
                          disabled={!mbsItems || percentDisable ? true : false}
                        />

                        <TextInput
                          xsLabel={4}
                          xsInside={4}
                          type="number"
                          label="Gap Fee"
                          // onChangeAction={handleHomeNumber}
                          numberOnly
                          // onBlurAction={homeNumberValidation}
                          value={gapFee}
                          // error={homeNumberError}
                          disabled
                          // required
                        />
                      </>
                    )}

                    {gstIncluded && privateFee && (
                      <>
                        <TextInput
                          xsLabel={4}
                          xsInside={4}
                          type="number"
                          label="GST percentage"
                          numberOnly
                          value={gstPercentage}
                          disabled={true}
                        />
                        <TextInput
                          xsLabel={4}
                          xsInside={4}
                          type="number"
                          label="GST amount"
                          numberOnly
                          value={(
                            privateFee && (gstPercentage / 100) * privateFee
                          ).toFixed(2)}
                          // error={homeNumberError}
                          // required
                          disabled={true}
                        />
                      </>
                    )}

                    {newItem && (
                      <Grid
                        container
                        item
                        xs={widthIs14 ? 12 : 6}
                        direction="column"
                        sx={{
                          maxWidth: widthIs14 ? "" : "590px !important",
                        }}
                        className={classes.Max}
                      >
                        <TextAreaNonGrid
                          xsLabel={4}
                          xsInside={8}
                          type="text"
                          label="Description"
                          onChangeAction={descriptionHandler}
                          onBlurAction={descriptionValidation}
                          value={description}
                          error={descriptionError}
                          required
                        />
                      </Grid>
                    )}

                    {/* <Fragment>
                      <Grid container spacing={2}>
                        <Grid item xs={4} className={`LabelBox`}>
                          <label>
                            Provider <sup>*</sup>
                          </label>
                        </Grid>
                        <Grid item xs={8}>
                          <div>
                            <Autocomplete
                              // limitTags={2}
                              value={patients && patients}
                              multiple
                              id="combo-box-demo"
                              options={
                                searchPatientOptions && searchPatientOptions
                              }
                              disabled={providerAll ? true : false}
                              key={sendToAll}
                              onChange={patientHandler}
                              onInputChange={patientInputHandler}
                              onBlur={patientsValidation}
                              noOptionsText={
                                searchPatientOptions?.length >= 0
                                  ? "Not found"
                                  : "Searching..."
                              }
                              //   open={patientInput}
                              open={inlineError ? false : patientInput}
                              disableCloseOnSelect
                              disableClearable
                              variant="outlined"
                              ListboxProps={{
                                style: { maxHeight: "15rem" },
                                position: "bottom-start",
                              }}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    variant="outlined"
                                    sx={{ borderRadius: "5px !important" }}
                                    label={`${option.first_name} ${option.last_name}`}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              getOptionLabel={(option) =>
                                `${option.first_name} ${option.last_name}`
                              }
                              renderOption={(props, option, { selected }) => (
                                <li
                                  {...props}
                                >{`${option.first_name} ${option.last_name}`}</li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  ListboxProps={{
                                    style: { maxHeight: "15rem" },
                                    position: "bottom-start",
                                  }}
                                  sx={{
                                    "& .MuiInputBase-input": {
                                      height: "1.5rem !important",
                                    },
                                    border: "0px !important",
                                    "& .MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
                                      {
                                        border: "0px !important",
                                      },
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "#ff000000 !important",
                                      },
                                    "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "#ff000000 !important",
                                      },
                                  }}
                                  {...params}
                                />
                              )}
                              sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#00000000 !important",
                                },
                                "& .MuiInputBase-input": {
                                  height: "1.5rem !important",
                                },
                                ".MuiAutocomplete-endAdornment": {
                                  display: "none !important",
                                },
                                "& .MuiOutlinedInput-root": {
                                  padding: "2px !important",
                                  borderRadius: "0px",
                                  backgroundColor: sendToAll
                                    ? "#e9e9e9 !important"
                                    : "white",
                                  border: patientError
                                    ? "1.5px solid #FF0000 !important"
                                    : "1px solid #E8E8E8",
                                  height:
                                    patients && patients.length > 2
                                      ? "150px !important"
                                      : "",
                                  overflow: "scroll",
                                },
                                "& .Mui-disabled .MuiOutlinedInput-notchedOutline":
                                  {
                                    backgroundColor: "#e9e9e9 !important",
                                    border: "0px !important",
                                  },
                              }}
                            />
                          </div>

                          {patientError ? (
                            <label className={classes.ErrorMsg}>
                              {patientError}
                            </label>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Fragment>

                    <Grid container spacing={2}>
                      <Grid item xs={4} className={`LabelBox`}></Grid>
                      <Grid item xs={8}>
                        <FormGroup>
                          <FormControlLabel
                            sx={{
                              "& .MuiTypography-root": {
                                fontSize: "14px",
                                // whiteSpace: "nowrap"
                              },
                            }}
                            control={
                              <Checkbox
                                // defaultChecked={
                                //   props.status &&
                                //   props.status.includes(item.label)
                                // }
                                disabled={props.disabled}
                                style={{
                                  color: "#615586",
                                }}
                                // checked={checked}
                                value={providerAll}
                                id={providerAll}
                                onChange={allProviderHandler}
                              />
                            }
                            label={"Apply to all provider"}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClickAction={submitHandler} text="Save" />

          <Button
            autoFocus
            bgLight={true}
            onMouseDownAction={props.handlePopupClose}
            text="Cancel"
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
