import React, { Fragment, useRef, useState, useEffect } from "react";
import { Alert, Backdrop, Grid } from "@mui/material";
import { Box } from "@mui/system";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import classes from "./CreateAppointment.module.css";
import theme from "../../Theme/Theme";
import { ThemeProvider } from "react-bootstrap";
import Button from "../../UI/Button/Button";
import PageFooter from "../../Layout/PageFooter/PageFooter";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import Loader from "../../UI/Loader/Loader";
import TextBox from "../../UI/FormGroup/TextBox/TextBox";
import DurationInput from "../../UI/FormGroup/DurationInput/DurationInput";
import { useNavigate, useLocation } from "react-router-dom";
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";
import storageService from "../../utils/localStorageHelpers";
import MonthYear from "../../UI/FormGroup/DurationInput/MonthYear";
import useMediaQuery from "@mui/material/useMediaQuery";
import DurationSelectInput from "../../UI/FormGroup/DurationInput/DurationSelectInput";
import CryptoJS from "crypto-js";
import CautionAlert from "../../utils/CautionAlert"

const AddAppointmentType = (props) => {
  const widthIs14 = useMediaQuery("(max-width:1279px)");
  const widthIs930 = useMediaQuery("(max-width:930px)");
  const widthIs1630 = useMediaQuery("(max-width:1630px)");

  const navigate = useNavigate();
  const location = useLocation();

  const middleName = useRef();
  const [loader, setLoader] = React.useState(false);
  const [error, setError] = useState("");
  const [apiError, setApiError] = useState(null);
  const [noData, setNoData] = useState(false);
  const [success, setSuccess] = useState("");

  const [users, setUsers] = useState(null);

  const [globalData, setGlobalData] = useState(null);

  const [siteData, setSiteData] = useState(null);

  const [typeError, setTypeError] = useState(null);

  const [durationError, setDurationError] = useState(null);

  const [hour, setHour] = useState(null);
  const [minute, setMinute] = useState(null);

  const [hourMinute, setHourMinute] = useState({
    hour: "00",
    minute: "00",
  });

  useEffect(() => {
    getGlobalData();
  }, []);

  const getGlobalData = async () => {
    setLoader(true);
    await axios({
      method: "GET",
      withCredentials: true,
      url: `${backendUrl}/api/appointment_types`,
    })
      .then(async (response) => {
        setLoader(false);
        let dt;
        setNoData(false);
        var bytes = CryptoJS.AES.decrypt(
          response?.data?.data,
          "secret key usr2323"
        );
        dt = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        setGlobalData(dt.map((e) => e.appointment_type));
      })
      .catch(function (error) {
        setLoader(false);
        setNoData(true);
        if (error.response) {
          setApiError(error.response.data.message);
        } else {
          setApiError("Something went wrong. Please try again");
        }
      });
  };

  const [form, setform] = useState({
    type: "",
    description: "",
  });

  const handleDescription = (e) => {
    setform({
      ...form,
      description: e.target.value,
    });
  };

  const handleType = (e) => {
    setTypeError("");
    setform({
      ...form,
      type: e.target.value,
    });
  };

  const hourHandleChange = (e) => {
    setDurationError("");
    setHour(e.target.value);
  };

  const hourValidation = (e) => {
    if (e && e.target) {
      setHour(e.target.value);
    }

    if (!hour) {
      setDurationError("Required");
    }
  };

  const minuteHandleChange = (e) => {
    setDurationError("");
    setMinute(e.target.value);
  };

  const minuteValidation = (e) => {
    if (e && e.target) {
      setMinute(e.target.value);
    }

    if (!minute) {
      setDurationError("Required");
    }
  };

  const typeValidation = async (e) => {
    if (e && e.target) {
      setform((prevalue) => {
        return {
          ...prevalue,
          [e.target.name]: e.target.value,
        };
      });
    }
    if (!form.type) {
      setTypeError("Required");
    }

    if (globalData.includes(form.type) || siteData.includes(form.type)) {
      setTypeError(`This appointment type already exist`);
    }
  };

  const submitValidationHandler = () => {
    typeValidation();
    hourValidation();
    minuteValidation();
  };

  const createData = async () => {
    setLoader(true);
    if (hour === "00" && minute === "00") {
      setDurationError("Duration cannot be zero");
      setLoader(false);
      return;
    }
    if (!form.type || !hour || !minute) {
      submitValidationHandler();
      setLoader(false);
      return;
    }
    if (typeError) {
      setLoader(false);
      return;
    } else {
      setLoader(true);
      await axios({
        method: "POST",
        data: {
          appointment_type: `${form.type}`,
          description: `${form.description}`,
          duration: `${hour}:${minute}`,
        },
        withCredentials: true,
        url: `${backendUrl}/api/appointment_type`,
      })
        .then(function (response) {
          navigate("/app/appointment-type/", {
            state: {
              showAlert: "Appointment type has been successfully saved",
            },
          });
          setLoader(false);
        })
        .catch(function (e) {
          setLoader(false);
          if (e.response === undefined) {
            setError("Something went wrong. Please try again");
          }
          if (e.response.data.message.includes("exists")) {
            setError(e.response.data.message);
          }
        });
    }
  };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "/app/appointment-type",
      iconName: "cil:filter",
      name: "Appointment Types",
    },
  ];

  const hourOptions = [
    {
      value: "00",
      label: "00",
    },
    {
      value: "01",
      label: "01",
    },
    {
      value: "02",
      label: "02",
    },
    {
      value: "03",
      label: "03",
    },
    {
      value: "04",
      label: "04",
    },
    {
      value: "05",
      label: "05",
    },
  ];

  const minuteOptions = [
    {
      value: "00",
      label: "00",
    },
    {
      value: "15",
      label: "15",
    },
    {
      value: "30",
      label: "30",
    },
    {
      value: "45",
      label: "45",
    },
  ];

  const retry = () => {
    setApiError(null);
    setTimeout(() => {
      getGlobalData();
    }, 100);
  };

  return (
    <ThemeProvider theme={theme}>
      <Breadcrumb options={breadCrumbData} />
      <Loader open={loader} />

      {apiError ? (
        <CautionAlert
          severity="error"
          open={apiError}
          content={apiError}
          onCloseActionB={retry}
          onCloseActionA={() => setApiError(null)}
          okText="Retry"
          cancelText="Dismiss"
        />
      ) : null}

      {error ? (
        <CautionAlert
          severity="error"
          open={error}
          content={error}
          onCloseActionB={() => setError(null)}
          okText="Ok"
          oneButton={true}
        />
      ) : null}

      {success ? (
        <Alert severity="success" sx={{ mb: 2, border: "1px solid green" }}>
          {success}
        </Alert>
      ) : null}

      <Fragment>
        <PageHeader left="Add appointment type" />

        <form>
          {noData ? (
            ""
          ) : (
            <Box className={classes.FormBox}>
              <Grid container direction="row" spacing={2}>
                <Grid
                  container
                  item
                  xs={widthIs14 ? 12 : 6}
                  direction="column"
                  sx={{ maxWidth: widthIs14 ? "" : "590px !important" }}
                  className={classes.Max}
                >
                  <TextBox
                    type="text"
                    label="Appointment Type"
                    onChangeAction={handleType}
                    onBlurAction={typeValidation}
                    error={typeError}
                    name="type"
                    placeholder="Appointment Type"
                    required
                    value={form.type}
                  />

                  <DurationSelectInput
                    md={6}
                    hourOptions={hourOptions}
                    minuteOptions={minuteOptions}
                    label="Duration"
                    hourOnChangeAction={hourHandleChange}
                    hourValue={hour}
                    minuteOnChangeAction={minuteHandleChange}
                    minuteValue={minute}
                    onBlurHour={hourValidation}
                    onBlurMinute={minuteValidation}
                    error={durationError}
                    required
                  />

                  {/* <DurationInput
                  md={6}
                  label="Duration"
                  duration={hourMinute}
                  hourChange={hourHandleChange}
                  minuteChange={minuteHandleChange}
                  onBlurHour={hourValidation}
                  onBlurMinute={minuteValidation}
                  error={durationError}
                  required
                /> */}
                </Grid>

                <Grid
                  container
                  item
                  xs={widthIs14 ? 12 : 6}
                  direction="column"
                  className={classes.Max}
                  sx={{
                    marginLeft: widthIs1630 ? "" : "80px",
                    maxWidth: widthIs14 ? "" : "590px !important",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={4} className={`LabelBox`}>
                      <label>Description</label>
                    </Grid>
                    <Grid item xs={8}>
                      <textarea
                        className="InputPrimary"
                        name="description"
                        onChange={handleDescription}
                        placeholder=""
                        ref={middleName}
                      />
                    </Grid>
                  </Grid>

                  {/* <MonthYear delimiter="/" /> */}
                </Grid>
              </Grid>
            </Box>
          )}
          <PageFooter
            right={
              <Fragment>
                {noData ? (
                  ""
                ) : (
                  <Box
                    sx={{
                      display: "inline-flex",
                      m: 1,
                    }}
                  >
                    <Button onMouseDownAction={createData} text="Save" />
                  </Box>
                )}

                <Box
                  sx={{
                    display: "inline-flex",
                    m: 1,
                  }}
                >
                  <Button
                    onMouseDownAction={() =>
                      navigate(
                        -1,
                        location.state && location.state.users
                          ? { state: { users: location.state.users } }
                          : null
                      )
                    }
                    bgLight={true}
                    text="Cancel"
                  />
                </Box>
              </Fragment>
            }
          />
        </form>
      </Fragment>
    </ThemeProvider>
  );
};

export default AddAppointmentType;
