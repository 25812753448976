import {
  Alert,
  Box,
  Grid,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../Layout/Breadcrumb/Breadcrumb";
import PageFooter from "../../../Layout/PageFooter/PageFooter";
import PageHeader from "../../../Layout/PageHeader/PageHeader";
import Theme from "../../../Theme/Theme";
import Button from "../../../UI/Button/Button";
import TextBox from "../../../UI/FormGroup/TextBox/TextBox";
import Loader from "../../../UI/Loader/Loader";
import classes from "./../Fees.module.css";
import axios from "axios";
import backendUrl from "../../../utils/backendUrl";
import CautionAlert from "../../../utils/CautionAlert";
import { useDispatch } from "react-redux";
import SelectBox from "../../../UI/FormGroup/SelectBox/SelectBox";
import DateSelector from "../../../UI/FormGroup/DateSelector/DateSelector";
import RadioGroup from "../../../UI/FormGroup/RadioGroup/RadioGroup";
import moment from "moment";
import TextAreaNonGrid from "../../../UI/FormGroup/TextArea/TextAreaNonGrid";

export default function EditMonthlyFile() {
  const widthIs14 = useMediaQuery("(max-width:1279px)");
  const widthIs930 = useMediaQuery("(max-width:930px)");
  const widthIs1630 = useMediaQuery("(max-width:1630px)");
  const location = useLocation();
  const { id } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [success, setSuccess] = useState(null);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [noData, setNoData] = useState(true);
  const [data, setData] = useState(null);

  const [url, setUrl] = useState(null);
  const [urlError, setUrlError] = useState(null);
  const [release, setRelease] = useState(null);
  const [releaseError, setReleaseError] = useState(null);
  const [effective, setEffective] = useState(null);
  const [effectiveError, setEffectiveError] = useState(null);
  const [showAlert, setShowAlert] = useState(true);
  const [type, setType] = useState();
  const [typeError, setTypeError] = useState(null);
  const [description, setDescription] = useState();

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    prefillData();
  }, [data]);

  const loadData = async () => {
    setLoader(true);
    if (location.state && location.state.data) {
      setData(location.state.data);
      setNoData(false);
      setLoader(false);
    } else {
      await axios({
        method: "GET",
        withCredentials: true,
        url: `${backendUrl}/api/monthly_mbs_files?id=${id}`,
      })
        .then(async (gb) => {
          setLoader(false);
          const global = gb.data && gb.data.data?.[0];
          if (!global) {
            throw new Error("Record not found");
          }

          setData(global);
          setNoData(false);
        })
        .catch((e) => {
          
          setNoData(true);
          setApiError("Something went wrong. Please try again");
          setLoader(false);
        });
    }
  };

  const prefillData = () => {
    if (data) {
      setUrl(data.url);
      setDescription(data.description);
      setType(data.fee_type);
      setShowAlert(data.show_alert);
      setRelease(new Date(data.release_date));
      setEffective(new Date(data.effective_date));
    }
  };

  const urlHandler = (e) => {
    setUrlError(null);
    setUrl(e.target.value);
  };

  const urlValidation = () => {
    if (!url) {
      setUrlError("Required");
      return false;
    }
    return true;
  };

  const releaseHandler = (newValue) => {
    setReleaseError(null);
    setRelease(newValue);
  };
  const releaseValidation = () => {
    let today = moment();
    if (!release) {
      setReleaseError("Required");
      return false;
    }
    if (!moment(release, moment.ISO_8601).isValid()) {
      setReleaseError("Invalid Date");
      return false;
    }
    // if (moment(release).isAfter(today)) {
    //   setExpiryDateError("Release date cannot be in future");
    //   return false;
    // }
    return true;
  };

  const effectiveHandler = (newValue) => {
    setEffectiveError(null);
    setEffective(newValue);
  };
  const effectiveValidation = () => {
    if (!effective) {
      setEffectiveError("Required");
      return false;
    }
    if (!moment(effective, moment.ISO_8601).isValid()) {
      setEffectiveError("Invalid Date");
      return false;
    }
    return true;
  };

  const typeHandler = (e) => {
    setTypeError(null);
    setType(e.target.value);
  };

  const showAlertHandler = (e) => {
    setShowAlert((prev) => {
      if (prev === null || (prev !== true && prev !== false)) {
        let val = e.target.value.toLowerCase() === "true";
        return val;
      }
      return !prev;
    });
  };

  const typeValidation = (e) => {
    if (!type) {
      setTypeError("Required");
      return false;
    }
    return true;
  };

  const descriptionHandler = (e) => {
    setDescription(e.target.value);
  };

  async function postData() {
    let body = {
      url,
      release_date: moment(release).format("YYYY-MM-DD"),
      effective_date: moment(effective).format("YYYY-MM-DD"),
      fee_type: type,
      description,
      show_alert: showAlert,
    };

    const result = await axios
      .patch(`${backendUrl}/api/monthly_mbs_files/${id}`, body, {
        withCredentials: true,
      })
      .then((res) => {
        setLoader(false);
        navigate(`/app/monthly/files`, {
          state: {
            showAlert: "Record has been successfully udpated",
          },
        });
      })
      .catch((e) => {
        setLoader(false);
        setError("Something went wrong. Please try again", true);
      });

    return result.data;
  }

  const submitValidation = () => {
    if (
      data.url === url &&
      data.release_date === moment(release).format("YYYY-MM-DD") &&
      data.effective_date === moment(effective).format("YYYY-MM-DD") &&
      data.fee_type === type &&
      data.show_alert === showAlert &&
      data.description === description
    ) {
      setError("You've made no changes");
      return;
    }
    let proceed = true;
    proceed = proceed && releaseValidation();
    proceed = proceed && effectiveValidation();
    proceed = proceed && urlValidation();
    proceed = proceed && typeValidation();

    if (proceed) {
      // All validations passed, proceed with submitting the form
      return true;
    } else {
      // Some validations failed, display error messages
      releaseValidation();
      urlValidation();
      effectiveValidation();
      typeValidation();

      return false;
    }
  };

  const submitHandler = async (event) => {
    setLoader(true);
    event.preventDefault();

    let isProceed = submitValidation();

    if (!isProceed) {
      setLoader(false);
      return false;
    }

    await postData();

    setLoader(false);
  };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "/app/tests",
      iconName: "medical-icon:i-care-staff-area",
      name: "Tests Management",
    },
  ];

  const typeOptions = [
    {
      label: "MBS Fees",
      value: "MBS Fees",
    },
    {
      label: "DVA Fees",
      value: "DVA Fees",
    },
    {
      label: "DVA Fees [Allied Health]",
      value: "DVA Fees [Allied Health]",
    },
  ];
  const booleanOptions = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];

  const retry = () => {
    setApiError(null);
    setTimeout(() => {
      loadData();
    }, 100);
  };

  return (
    <ThemeProvider theme={Theme}>
      <Loader open={loader} />
      <Breadcrumb options={breadCrumbData} />
      {apiError ? (
        <CautionAlert
          severity="error"
          open={apiError}
          content={apiError}
          onCloseActionB={() => setApiError()}
          onCloseActionA={retry}
          okText="Retry"
          cancelText="Dismiss"
        />
      ) : null}
      {error ? (
        <CautionAlert
          severity="error"
          open={error}
          content={error}
          onCloseActionB={() => setError(null)}
          okText="Ok"
          oneButton={true}
        />
      ) : null}

      {success ? (
        <CautionAlert
          severity="success"
          success={true}
          open={success}
          content={success}
          onCloseActionB={() => setSuccess(null)}
          okText="Ok"
          oneButton={true}
        />
      ) : null}

      <PageHeader left="Add Monthly File" />
      <Loader open={loader} />

      <form>
        {!noData && (
          <Box className={classes.FormBox}>
            <Grid container direction="row" spacing={2}>
              {/* First Column */}
              <Grid
                container
                item
                xs={widthIs14 ? 12 : 6}
                direction="column"
                sx={{ maxWidth: widthIs14 ? "" : "590px !important" }}
                className={classes.Max}
              >
                <TextBox
                  xsLabel={4}
                  xsInside={8}
                  type="text"
                  label="Url"
                  onChangeAction={urlHandler}
                  value={url}
                  onBlurAction={urlValidation}
                  error={urlError}
                  placeholder="Url"
                  required
                />
                <DateSelector
                  sxInside={{ maxWidth: "400px !important" }}
                  xsLabel={4}
                  mdInside={8}
                  xsInside={8}
                  label="Release"
                  onChangeAction={releaseHandler}
                  value={release}
                  onBlurAction={releaseValidation}
                  error={releaseError}
                  required
                />
                <DateSelector
                  sxInside={{ maxWidth: "400px !important" }}
                  xsLabel={4}
                  mdInside={8}
                  xsInside={8}
                  label="Effective"
                  onChangeAction={effectiveHandler}
                  value={effective}
                  onBlurAction={effectiveValidation}
                  error={effectiveError}
                  required
                />
              </Grid>

              <Grid
                container
                item
                xs={widthIs14 ? 12 : 6}
                direction="column"
                className={classes.Max}
                sx={{
                  marginLeft: widthIs1630 ? "" : "80px",
                  maxWidth: widthIs14 ? "" : "590px !important",
                }}
              >
                <SelectBox
                  xsLabel={4}
                  options={typeOptions}
                  xsInside={8}
                  label="Type"
                  onChangeAction={typeHandler}
                  value={type}
                  onBlurAction={typeValidation}
                  error={typeError}
                  noNone={true}
                />
                <RadioGroup
                  options={booleanOptions}
                  onChangeAction={showAlertHandler}
                  name="usertype"
                  value={showAlert}
                  label="Show Alert"
                  md={6}
                  required
                />
                <TextAreaNonGrid
                  sx={{
                    paddingTop: "20px !important",
                    paddingBottom: "4px !important",
                  }}
                  xsLabel={4}
                  xsInside={8}
                  type="text"
                  label="Description"
                  onChangeAction={descriptionHandler}
                  value={description}
                  placeholder="Description"
                />
              </Grid>
            </Grid>
          </Box>
        )}
        <PageFooter
          right={
            <Fragment>
              {!noData && (
                <Box
                  sx={{
                    px: 2,
                  }}
                >
                  <Button
                    text="Submit"
                    // type="submit"
                    onClickAction={submitHandler}
                  />
                </Box>
              )}

              <Box>
                <Button
                  fontSize="14px"
                  onMouseDownAction={() => navigate(-1)}
                  bgLight={true}
                  text="Cancel"
                />
              </Box>
            </Fragment>
          }
        />
      </form>
    </ThemeProvider>
  );
}
