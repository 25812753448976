import timestampHandler from "../utils/timstampHandler";

const siteTableColumns = [
  {
    text: "Site Name",
    dataField: "site_name",
  },
  {
    text: "Location ID",
    dataField: "location_id",
  },
  {
    text: "Business Name",
    dataField: "business_name",
  },
  {
    text: "CIN",
    dataField: "cin_number",
    formatter:(value) =>{
      return value ? value : "N/A"
    }
  },
  {
    text: "GSTIN",
    dataField: "gstin_number",
    formatter:(value) =>{
      return value ? value : "N/A"
    }
  },
  {
    text: "Phone Number",
    dataField: "work_phone",
  },
  {
    text: "Email",
    dataField: "work_email",
  },

  {
    text: "Active",
    dataField: "active",
    formatter:(value) =>{
      return value ? "Yes" : "No"
    }
  },

  {
    dataField: "action",
    text: "Action",
  },
];

export default {
  siteTableColumns,
};
