import React, { Fragment, useEffect, useState } from "react";
import DataTable from "../../Layout/DataTable/DataTable";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import Button from "../../UI/Button/Button";
import { Icon } from "@iconify/react";
import { useNavigate, useLocation } from "react-router-dom";

// Import these files for breadcrumbs
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";

import CautionAlert from "../../utils/CautionAlert";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import LoaderWithoutBackdrop from "../../UI/Loader/LoaderWithoutBackdrop";
import Loader from "../../UI/Loader/Loader";
import { checkPrivilage } from "../../utils/checkPrivilage";
import columns from "../../Resources/mimsSubColumns";
import Theme from "../../Theme/Theme";
import { DropdownButton } from "react-bootstrap";
import ActionButton from "../../UI/ActionButton/ActionButtonBox";
import UploadTemplate from "./UploadTemplate";
import TabContext from "@mui/lab/TabContext/TabContext";
import TabPanel from "@mui/lab/TabPanel/TabPanel";
import TabLists from "../Dashboard/TabLists";
import { Typography } from "@mui/material";
import UploadPopup from "./UploadPopup";

const Medicines = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [error, setError] = useState("");
  const [loader, setLoader] = React.useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [success, setSuccess] = useState("");
  const [switchItem, setSwitchItem] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [openAddTemplate, setOpenAddTemplate] = useState(false);
  const [tabIndex, setTabIndex] = useState(1);
  const [brandUpload, setBrandUpload] = useState(false);
  const [genericUpload, setGenericUpload] = useState(false);
  const [routeUpload, setRouteUpload] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoader(true);
    await axios({
      method: "GET",
      url: `${backendUrl}/api/medicine/counts`,
      withCredentials: true,
    })
      .then((response) => {
        if (response.data?.data) {
          var dt = response.data?.data;
          setData(dt);
          setLoader(false);
        } else {
          setApiError("Something went wrong. Please try again");
          setLoader(false);
        }
      })
      .catch((e) => {
        
        setApiError("Something went wrong. Please try again");
        setLoader(false);
      });
  };

  const successHandler = (msg) => {
    setSuccess(msg);
    loadData();
  };

  const confirmActivate = () => {
    activationHandler(switchItem);
    setSwitchItem(null);
  };

  const activationHandler = async (item) => {
    setLoader(true);
    await axios({
      method: "PATCH",
      withCredentials: true,
      data: { active: !item.active },
      url: `${backendUrl}/api/site/${item.id}`,
    })
      .then(() => {
        setLoader(false);
        setSuccess(
          `Site has been ${
            item.active ? "deactivated" : "activated"
          } successfully`
        );
        loadData();
      })
      .catch(function (error) {
        setLoader(false);
        setError("Something went wrong. Please try again");
      });
  };

  let tabData = [
    {
      label: "Brand Names",
      value: 1,
    },
    {
      label: "Generic Names",
      value: 2,
    },
    {
      label: "Medicine Routes",
      value: 3,
    },
  ];

  const tabHandler = (e, newValue) => {
    setTabIndex(newValue);
  };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "/app/mims-management",
      iconName: "fa6-solid:capsules",
      name: "Medication Management",
    },
  ];

  const retry = () => {
    setApiError(null);
    setTimeout(() => {
      loadData();
    }, 100);
  };

  const handleDelete = async (id) => {
    setDeleteItem(null);
    setLoader(true);
    await axios({
      method: "DELETE",
      withCredentials: true,
      url: `${backendUrl}/api/prescription_template/${id}`,
    })
      .then((response) => {
        setLoader(false);
        setSuccess("Prescription template has been successfully deleted");
        loadData();
      })
      .catch(function (error) {
        setLoader(false);
        setError("Something went wrong. Please try again");
      });
  };
  
  const uploadBrand = () => {
    setBrandUpload(true);
  };
  const brandUploadDone = () =>{
    setSuccess("Brand names has been successfully uploaded");
    loadData();
  }
  const brandUploadClose = () =>{
    setBrandUpload(false)
  }

  const uploadGeneric = () => {
    setGenericUpload(true);
  };
  const genericUploadDone = () =>{
    setSuccess("Generic names has been successfully uploaded");
    loadData();
  }
  const genericUploadClose = () =>{
    setGenericUpload(false)
  }

  const uploadRoute = () => {
    setRouteUpload(true);
  };
  const routeUploadDone = () =>{
    setSuccess("Medicine routes has been successfully uploaded");
    loadData();
  }
  const routeUploadClose = () =>{
    setRouteUpload(false)
  }

  return (
    <Fragment>
      <Loader open={loader} />
      {/* <CautionAlert
        severity="error"
        open={open}
        content="Are you sure you want to delete this item?"
        onCloseActionB={deleteConfirm}
        onCloseActionA={handleClose}
        okText="Delete"
        cancelText="Cancel"
      />
       */}
      <UploadTemplate
        open={openAddTemplate}
        handlePopupClose={() => setOpenAddTemplate(false)}
        successHandler={successHandler}
      />
      {deleteItem && (
        <CautionAlert
          severity="error"
          open={deleteItem}
          content={`Are you sure you want to delete this template?`}
          onCloseActionB={() => handleDelete(deleteItem?.id)}
          onCloseActionA={() => setDeleteItem(null)}
          okText="Yes"
          cancelText="No"
        />
      )}
      {error ? (
        <>
          <CautionAlert
            severity="error"
            open={error}
            content={error}
            oneButton={true}
            onCloseActionB={() => {
              setError(null);
            }}
            okText="Ok"
          />
        </>
      ) : null}
      <CautionAlert
        severity="error"
        open={apiError}
        content={apiError}
        onCloseActionB={retry}
        onCloseActionA={() => setApiError(null)}
        okText="Retry"
        cancelText="Dismiss"
      />
      <CautionAlert
        severity="success"
        success={true}
        open={success}
        content={success}
        onCloseActionB={() => setSuccess(null)}
        okText="Ok"
        oneButton={true}
      />
      {location.state?.showAlert && (
        <CautionAlert
          severity="success"
          success={true}
          open={location.state.showAlert}
          content={location.state.showAlert}
          onCloseActionB={() =>
            navigate("/app/mims/subscriptions", location.state.showAlert, {})
          }
          okText="Ok"
          oneButton={true}
        />
      )}

      <UploadPopup
        type="brand"
        title="Brand Names"
        handlePopupOpen={uploadBrand}
        PopUpOpen={brandUpload}
        success={brandUploadDone}
        handlePopupClose={brandUploadClose}
      />

      <UploadPopup
        type="generic"
        title="Generic Names"
        handlePopupOpen={uploadGeneric}
        PopUpOpen={genericUpload}
        success={genericUploadDone}
        handlePopupClose={genericUploadClose}
      />

      <UploadPopup
        type="route"
        title="Medicine Routes"
        handlePopupOpen={uploadRoute}
        PopUpOpen={routeUpload}
        success={routeUploadDone}
        handlePopupClose={routeUploadClose}
      />

      <Breadcrumb options={breadCrumbData} />
      <PageHeader left={"Medicines"} />

      <TabLists
        data={tabData}
        // uploadAMA={uploadAMA}
        // uploadWorkcover={uploadWorkcover}
        // uploadPrivateFee={uploadPrivateFee}
        value={tabIndex}
        handleChange={tabHandler}
      />

      <TabContext
        className="SubSectionRow"
        style={{
          height: "100%",
          margin: "0px !important",
          padding: "0px !important",
        }}
        value={tabIndex}
      >
        <TabPanel sx={{ padding: "0px !important" }} value={1}>
          {data && (
            <>
              <Typography sx={{ padding: "15px 10px" }}>
                Active brand names: {data.brand}
              </Typography>
              <Button
                fontSize="14px"
                onClickAction={uploadBrand}
                text="Upload Brand Names"
                startIcon={
                  <Icon style={{ fontSize: "14px" }} icon="akar-icons:plus" />
                }
              ></Button>
            </>
          )}
        </TabPanel>
        <TabPanel sx={{ padding: "0px !important" }} value={2}>
          {" "}
          {data && (
            <>
              <Typography sx={{ padding: "15px 10px" }}>
                Active generic names: {data.generic}
              </Typography>
              <Button
                fontSize="14px"
                onClickAction={uploadGeneric}
                text="Upload Generic Names"
                startIcon={
                  <Icon style={{ fontSize: "14px" }} icon="akar-icons:plus" />
                }
              ></Button>
            </>
          )}
        </TabPanel>
        <TabPanel sx={{ padding: "0px !important" }} value={3}>
          {" "}
          {data && (
            <>
              <Typography sx={{ padding: "15px 10px" }}>
                Active medicine routes: {data.route}
              </Typography>
              <Button
                fontSize="14px"
                onClickAction={uploadRoute}
                text="Upload Medicine Routes"
                startIcon={
                  <Icon style={{ fontSize: "14px" }} icon="akar-icons:plus" />
                }
              ></Button>
            </>
          )}
        </TabPanel>
      </TabContext>
    </Fragment>
  );
};

export default Medicines;
