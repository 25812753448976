import React from "react";
import { memo } from "react";

const Images = ({image}) => {
  return (
    <div
      style={{
        backgroundImage: `url(${
          image + `?datetime=${new Date().getTime()}`
        })`,
        height: "100px",
        width: "150px",
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    ></div>
  );
};

export default memo(Images);
