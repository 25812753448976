import {
  Alert,
  Box,
  Grid,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";
import PageFooter from "../../Layout/PageFooter/PageFooter";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import Theme from "../../Theme/Theme";
import Button from "../../UI/Button/Button";
import TextBox from "../../UI/FormGroup/TextBox/TextBox";
import Loader from "../../UI/Loader/Loader";
import classes from "./EmailSubscription.module.css";
import UploadButton from "../../UI/FormGroup/UploadButton/UploadButton";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import storageService from "../../utils/localStorageHelpers";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import CautionAlert from "../../utils/CautionAlert";
import { useDispatch } from "react-redux";
import { saveSite } from "../../../redux/site";
import CryptoJS from "crypto-js";
import CustomRadioGroup from "../../UI/FormGroup/RadioGroup/RadioGroup";
import SearchSite from "../../UI/SearchSite/SearchSite";
import moment from "moment";

export default function AddEmailSubscription() {
  const widthIs14 = useMediaQuery("(max-width:1279px)");
  const widthIs930 = useMediaQuery("(max-width:930px)");
  const widthIs1630 = useMediaQuery("(max-width:1630px)");
  const location = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [success, setSuccess] = useState(null);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);

  const [credit, setCredit] = useState(null);
  const [creditError, setCreditError] = useState(null);
  const [site, setSite] = useState(null);
  const [siteError, setSiteError] = useState(null);
  const [sender, setSender] = useState(null);
  const [senderError, setSenderError] = useState(null);

  const creditHandler = (e) => {
    setCredit(e.target.value);
  };

  const creditValidation = () => {
    if (!credit) {
      setCreditError("Required");
      return false;
    }
    return true;
  };
  const siteValidation = (msg) => {
    if (msg) {
      setSiteError(msg);
      return false;
    }
    if (!site) {
      setSiteError("Required");
      return false;
    }
    return true;
  };

  const selectedSite = (value) => {
    setSite(value);
  };

  const senderHandler = (e) => {
    setSenderError(null);
    setSender(e.target.value);
  };

  const senderValidation = (e) => {
    if (!sender) {
      setSenderError("Required");
      return false;
    }
    return true;
  };

  async function postData() {
    let body = {
      siteId: site.id,
      email_credit: credit,
      email_sender: sender,
      email_subscribed: moment().format("YYYY-MM-DD"),
      type: "Email",
    };

    const result = await axios
      .post(
        `${backendUrl}/api/sms_email_subscription`,
        body,
        { withCredentials: true },
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((res) => {
        setLoader(false);
        navigate(`/app/email-subscription`, {
          state: {
            showAlert: "Email subscription has been created successfully",
          },
        });
      })
      .catch((e) => {
        setLoader(false);
        if (e.response?.data?.message?.includes("404")) {
          setError("Email/Credit you entered is incorrect");
          return;
        }
        setError("Something went wrong. Please try again", true);
      });

    return result.data;
  }

  const submitValidation = () => {
    let proceed = true;
    proceed = proceed && creditValidation();
    proceed = proceed && siteValidation();
    proceed = proceed && senderValidation();

    if (proceed) {
      // All validations passed, proceed with submitting the form
      return true;
    } else {
      creditValidation();
      siteValidation();
      senderValidation();

      return false;
    }
  };

  const submitHandler = async (event) => {
    setLoader(true);
    event.preventDefault();

    let isProceed = submitValidation();

    if (!isProceed) {
      setLoader(false);
      return false;
    }

    await postData();

    setLoader(false);
  };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "/app/sms-subscription",
      iconName: "fa-solid:sms",
      name: "SMS Subscriptions",
    },
  ];
  return (
    <ThemeProvider theme={Theme}>
      <Loader open={loader} />
      <Breadcrumb options={breadCrumbData} />
      {error ? (
        <CautionAlert
          severity="error"
          open={error}
          content={error}
          onCloseActionB={() => setError(null)}
          okText="Ok"
          oneButton={true}
        />
      ) : null}

      {success ? (
        <CautionAlert
          severity="success"
          success={true}
          open={success}
          content={success}
          onCloseActionB={() => setSuccess(null)}
          okText="Ok"
          oneButton={true}
        />
      ) : null}

      <PageHeader left="Add Email Subscription" />
      <Loader open={loader} />

      <form>
        <Box className={classes.FormBox}>
          <Grid container direction="row" spacing={2}>
            {/* First Column */}
            <Grid
              container
              item
              xs={widthIs14 ? 12 : 6}
              direction="column"
              sx={{ maxWidth: widthIs14 ? "" : "590px !important" }}
              className={classes.Max}
            >
              <SearchSite
                xsLabel={4}
                xsInside={8}
                siteValidation={siteValidation}
                siteError={siteError}
                selectedSite={selectedSite}
              />
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Sender (email)"
                onChangeAction={senderHandler}
                value={sender}
                onBlurAction={senderValidation}
                error={senderError}
                placeholder="Credit"
                required
              />
            </Grid>

            <Grid
              container
              item
              xs={widthIs14 ? 12 : 6}
              direction="column"
              className={classes.Max}
              sx={{
                marginLeft: widthIs1630 ? "" : "80px",
                maxWidth: widthIs14 ? "" : "590px !important",
              }}
            >
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Credit"
                onChangeAction={creditHandler}
                value={credit}
                onBlurAction={creditValidation}
                error={creditError}
                placeholder="Credit"
                required
              />
            </Grid>
          </Grid>
        </Box>
        <PageFooter
          right={
            <Fragment>
              <Box
                sx={{
                  px: 2,
                }}
              >
                <Button
                  text="Submit"
                  // type="submit"
                  onClickAction={submitHandler}
                />
              </Box>

              <Box>
                <Button
                  fontSize="14px"
                  onMouseDownAction={() => navigate(-1)}
                  bgLight={true}
                  text="Cancel"
                />
              </Box>
            </Fragment>
          }
        />
      </form>
    </ThemeProvider>
  );
}
