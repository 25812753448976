import timestampHandler from "../utils/timstampHandler";

const smsSubscriptionTableColumns = [
  {
    dataField: "id",
    text: "id",
    hidden: true,
  },
  {
    dataField: "site",
    text: "Site Name",
    formatter: (value) => {
      return value?.site_name;
    },
  },
  {
    dataField: "site",
    text: "Business Name",
    formatter: (value) => {
      return value?.business_name;
    },
  },
  {
    text: "SMS Key",
    dataField: "sms_key",
  },
  {
    text: "Sender Name",
    dataField: "sms_sender",
  },
  {
    text: "Credit",
    dataField: "sms_credit",
  },
  {
    text: "Status",
    dataField: "status",
  },
  {
    text: "Actions",
    dataField: "action",
  },
  // {
  //   dataField: "keys",
  //   text: "SMS key",
  //   formatter: (value) => {
  //       let smsKeyObj = value.find((k)=> k.name === "sms_key")
  //     return smsKeyObj.key
  //   },

  // },
  // {
  //   text: "Created at",
  //   dataField: "created_at",
  //   formatter: (value) => {
  //     return timestampHandler(value, "DD/MM/YYYY");
  //   },
  // },
  // {
  //   text: "Updated at",
  //   dataField: "updated_at",
  //   formatter: (value) => {
  //     return timestampHandler(value, "DD/MM/YYYY");
  //   },
  // },
];

export default {
  smsSubscriptionTableColumns,
};
