import React, { Fragment, useRef, useState } from "react";
import PropTypes from "prop-types";
import Button from "../../UI/Button/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Box } from "@mui/system";
import {
  Alert,
  Autocomplete,
  Backdrop,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import classes from "./ProdaException.module.css";
import UploadButton from "../../UI/FormGroup/UploadButton/UploadButton";
import storageService from "../../utils/localStorageHelpers";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import Loader from "../../UI/Loader/Loader";
import TextInput from "../../UI/FormGroup/TextBox/TextInput";
import BootstrapTable from "react-bootstrap-table-next";
import styles from "../../Layout/DataTable/DataTable.module.css";
import CautionAlert from "../../utils/CautionAlert";
import { useEffect } from "react";

export default function UploadPopup(props) {
  const widthIs14 = useMediaQuery("(max-width:1279px)");

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
      maxWidth: "850px",
      borderRadius: "0px",
      width: "660px",
    },

    "& .MuiDialogContent-root": {
      padding: theme.spacing(1),
      borderRadius: 0,
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
      borderRadius: 0,
    },
  }));

  const [file, setFile] = useState(null);
  const [fileUploadError, setFileUploadError] = useState(null);
  const [loader, setLoader] = useState(null);
  const [error, setError] = useState(null);

  const [popupError, setPopupError] = useState(false);

  const uploadedFile = useRef();

  const fileSelected = (e) => {
    const file = uploadedFile.current?.files;
  };

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          textAlign: "left",
          backgroundColor: "#615586",
          color: "white",
        }}
        {...other}
      >
        {children}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  async function postData() {
    const file = uploadedFile.current?.files;

    setLoader(true);
    const formData = new FormData();

    if (!file[0]) {
      setPopupError("No File selected");
      setLoader(false);
      return;
    } else {
      formData.append("upload", uploadedFile.current?.files[0]);

      formData.append("type", props.type)

      await axios
        .post(
          props.url,
          formData,
          { withCredentials: true },
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((res) => {
          setLoader(false);
          props.handlePopupClose();
          props.success(true);
        })
        .catch((e) => {
          setLoader(false);
          setPopupError("Something went wrong. Please try again");

          let errorMsg = e.response.data.message;
          
          if (errorMsg.includes("invalid")) {
            setPopupError(
              "The file uploaded does not match the column or header values. Please check the file and try again"
            );
          } else if (errorMsg.includes("Duplicate")) {
            setPopupError(
              "Duplicate item number found. Please make changes and try again"
            );
          } else if (errorMsg.includes("wrong")) {
            setPopupError(
              "The file uploaded does not match the column or header values. Please check the file and try again"
            );
          } else {
            setPopupError("Something went wrong. Please try again");
          }
        });
    }
  }
  const errorFromPopupClose = () => {
    setPopupError(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={props.handlePopupClose}
        aria-labelledby="customized-dialog-title"
        open={props.PopUpOpen}
      >
        <Loader open={loader} />

        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.handlePopupClose}
        >
          {props.title}
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <CautionAlert
            oneButton={true}
            severity="error"
            open={popupError}
            content={popupError}
            onCloseActionB={errorFromPopupClose}
            okText="Ok"
          />

          {error ? (
            <>
              <Alert
                severity="error"
                sx={{
                  mb: 2,
                  border: "1px solid red",
                  zIndex: "99999 !important",
                  position: "relative",
                }}
              >
                {error}
              </Alert>
              <Backdrop
                sx={{ backgroundColor: "#00000000", zIndex: 500 }}
                open={error}
              ></Backdrop>
            </>
          ) : null}

          <form>
            <Box className={classes.FormBox}>
              <Grid container direction="row" spacing={2}>
                <Grid
                  container
                  item
                  xs={12}
                  direction="column"
                  sx={{ maxWidth: widthIs14 ? "" : "590px !important" }}
                  className={classes.Max}
                >
                  <Fragment>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        sx={props.sxLabel || ""}
                        xs={props.xsLabel || 4}
                        className={`LabelBox`}
                      >
                        <label>
                          Upload <sup>*</sup>
                        </label>
                      </Grid>

                      <Grid item sx={props.sx || ""} xs={props.xsInside || 8}>
                        <input
                          className={`InputPrimary BackWhite ${
                            fileUploadError ? "ErrorBorder" : null
                          } }`}
                          accept=".xlsx"
                          id="upload"
                          type="file"
                          onInput={(e) => fileSelected(e)}
                          ref={uploadedFile}
                          name="upload"
                          style={{ backgroundColor: "white !important" }}
                        />
                        {fileUploadError ? (
                          <label className={classes.ErrorMsg}>
                            {fileUploadError}
                          </label>
                        ) : null}

                        <div>
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                          >
                            <ul>
                              <li>Allowed format : .xls, .xlsx</li>
                            </ul>
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </Fragment>
                </Grid>
              </Grid>
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClickAction={postData} text="Save" />

          <Button
            autoFocus
            bgLight={true}
            onClickAction={props.handlePopupClose}
            text="Cancel"
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
