import {
  Alert,
  Box,
  Grid,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";
import PageFooter from "../../Layout/PageFooter/PageFooter";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import Theme from "../../Theme/Theme";
import Button from "../../UI/Button/Button";
import TextBox from "../../UI/FormGroup/TextBox/TextBox";
import Loader from "../../UI/Loader/Loader";
import classes from "./UserSubscription.module.css";
import UploadButton from "../../UI/FormGroup/UploadButton/UploadButton";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import storageService from "../../utils/localStorageHelpers";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import CautionAlert from "../../utils/CautionAlert";
import { useDispatch } from "react-redux";
import { saveSite } from "../../../redux/site";
import CryptoJS from "crypto-js";
import RadioGroup from "../../UI/FormGroup/RadioGroup/RadioGroup";
import SearchSite from "../../UI/SearchSite/SearchSite";
import moment from "moment";
import SelectBox from "../../UI/FormGroup/SelectBox/SelectBox";
import DateSelector from "../../UI/FormGroup/DateSelector/DateSelector";

export default function AddUserSubscription() {
  const widthIs14 = useMediaQuery("(max-width:1279px)");
  const widthIs930 = useMediaQuery("(max-width:930px)");
  const widthIs1630 = useMediaQuery("(max-width:1630px)");
  const location = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [success, setSuccess] = useState(null);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);

  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [site, setSite] = useState(null);
  const [siteError, setSiteError] = useState(null);
  const [sender, setSender] = useState(null);
  const [senderError, setSenderError] = useState(null);
  const [role, setRole] = useState(null);
  const [roleOptions, setRoleOptions] = useState(null);
  const [roleError, setRoleError] = useState(null);
  const [noData, setNoData] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [startDateError, setStartDateError] = useState(null);
  const [expiryDate, setExpiryDate] = useState();
  const [expiryDateError, setExpiryDateError] = useState();
  const [userType, setUserType] = useState(null);
  const [userTypeError, setUserTypeError] = useState(null);
  const [allowLogin, setAllowLogin] = useState(false);

  useEffect(() => {
    loadPaidRoles();
  }, []);

  useEffect(() => {
    if (startDate) {
      const start = moment(startDate);

      // Adding one year to the current date
      const expiry = start.add(1, "years");

      setExpiryDate(new Date(expiry));
    }
  }, [startDate]);

  const loadPaidRoles = async () => {
    setLoader(true);
    await axios({
      method: "GET",
      url: `${backendUrl}/api/role?paid_role=true`,
      withCredentials: true,
    })
      .then((response) => {
        setLoader(false);
        if (response.data?.data) {
          var dt = response.data?.data;
          dt = dt?.map((r) => {
            return {
              ...r,
              value: r.id,
              label: r.name,
            };
          });
          setRoleOptions(dt);
          setNoData(false);
        } else {
          setError("Something went wrong. Please try again");
          setNoData(true);
        }
      })
      .catch((e) => {
        setNoData(true);
        setLoader(false);
        setError("Something went wrong. Please try again");
      });
  };

  const siteValidation = (msg) => {
    if (msg) {
      setSiteError(msg);
      return false;
    }
    if (!site) {
      setSiteError("Required");
      return false;
    }
    return true;
  };

  const selectedSite = (value) => {
    setSite(value);
  };

  const roleHandler = (e) => {
    setRoleError(null);
    let r = roleOptions.find((r) => r.id === e.target.value);
    setRole(r);
  };

  const roleValidation = () => {
    if (!role) {
      setRoleError("Required");
      return false;
    }
    return true;
  };

  const allowLoginHandler = (e) => {
    let value = e.target.value;
    if (value === "true" || value === true) {
      setAllowLogin(true);
    } else {
      setAllowLogin(false);
    }
  };

  const startDateHandler = (value) => {
    setStartDateError(null);
    setStartDate(value);
  };

  const startDateValidation = () => {
    if (!startDate) {
      setStartDateError("Required");
      return false;
    }

    if (startDate && !moment(startDate, moment.ISO_8601).isValid()) {
      setStartDateError("Invalid date");
      return false;
    }
    return true;
  };

  const expiryDateHandler = (value) => {
    setExpiryDateError(null);
    setExpiryDate(value);
  };

  const expiryDateValidation = () => {
    let start = moment(startDate).format("YYYY-MM-DD");
    if (moment(expiryDate).isBefore(start)) {
      setExpiryDateError("Expiry date cannot be before start date");
      return false;
    }

    if (expiryDate && !moment(expiryDate, moment.ISO_8601).isValid()) {
      setExpiryDateError("Invalid date");
      return false;
    }

    return true;
  };

  const userTypeHandler = (e) => {
    let value = e.target.value;
    setUserTypeError();
    setUserType(value);
  };

  const userTypeValidation = () => {
    if (!userType) {
      setUserTypeError("Required");
      return false;
    }
    return true;
  };

  const userTypeOptions = [
    {
      label: "Full Time",
      value: "fulltime",
    },
    {
      label: "Part Time",
      value: "parttime",
    },
  ];

  const allowLoginOptions = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];

  

  async function postData() {
    let body = {
      siteId: site.id,
      user_type: userType,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      expiry_date: moment(expiryDate).format("YYYY-MM-DD"),
      allow_login: allowLogin,
      roleId: role.id,
      status: "Active",
    };

    const result = await axios
      .post(
        `${backendUrl}/api/user_subscription`,
        body,
        { withCredentials: true },
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((res) => {
        setLoader(false);
        navigate(`/app/user-subscription`, {
          state: {
            showAlert: "User subscription has been created successfully",
          },
        });
      })
      .catch((e) => {
        setLoader(false);
        setError("Something went wrong. Please try again", true);
      });

    return result.data;
  }

  const submitValidation = () => {
    let proceed = true;
    proceed = proceed && siteValidation();
    proceed = proceed && roleValidation();
    proceed = proceed && userTypeValidation();
    proceed = proceed && startDateValidation();
    proceed = proceed && expiryDateValidation();

    if (proceed) {
      // All validations passed, proceed with submitting the form
      return true;
    } else {
      // Some validations failed, display error messages
      siteValidation();
      roleValidation();
      userTypeValidation();
      startDateValidation();
      expiryDateValidation();
      return false;
    }
  };

  const submitHandler = async (event) => {
    setLoader(true);
    event.preventDefault();

    let isProceed = submitValidation();

    if (!isProceed) {
      setLoader(false);
      return false;
    }

    await postData();

    setLoader(false);
  };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "/app/user-subscription",
      iconName: "mdi:shield-user",
      name: "User Subscriptions",
    },
  ];
  return (
    <ThemeProvider theme={Theme}>
      <Loader open={loader} />
      <Breadcrumb options={breadCrumbData} />
      {error ? (
        <CautionAlert
          severity="error"
          open={error}
          content={error}
          onCloseActionB={() => setError(null)}
          okText="Ok"
          oneButton={true}
        />
      ) : null}

      {success ? (
        <CautionAlert
          severity="success"
          success={true}
          open={success}
          content={success}
          onCloseActionB={() => setSuccess(null)}
          okText="Ok"
          oneButton={true}
        />
      ) : null}

      <PageHeader left="Add User Subscription" />
      <Loader open={loader} />

      <form>
        {noData ? (
          ""
        ) : (
          <Box className={classes.FormBox}>
            <Grid container direction="row" spacing={2}>
              {/* First Column */}
              <Grid
                container
                item
                xs={widthIs14 ? 12 : 6}
                direction="column"
                sx={{ maxWidth: widthIs14 ? "" : "590px !important" }}
                className={classes.Max}
              >
                <SearchSite
                  xsLabel={4}
                  xsInside={8}
                  siteValidation={siteValidation}
                  siteError={siteError}
                  selectedSite={selectedSite}
                />
                <SelectBox
                  label="Role"
                  options={roleOptions}
                  value={role?.value}
                  onChangeAction={roleHandler}
                  onBlurAction={roleValidation}
                  error={roleError}
                />
                <RadioGroup
                  options={allowLoginOptions}
                  onChangeAction={allowLoginHandler}
                  name="allow_login"
                  value={allowLogin}
                  label="Allow Login"
                  md={6}
                  required
                />
              </Grid>

              <Grid
                container
                item
                xs={widthIs14 ? 12 : 6}
                direction="column"
                className={classes.Max}
                sx={{
                  marginLeft: widthIs1630 ? "" : "80px",
                  maxWidth: widthIs14 ? "" : "590px !important",
                }}
              >
                <RadioGroup
                  options={userTypeOptions}
                  onChangeAction={userTypeHandler}
                  onBlur={userTypeValidation}
                  name="usertype"
                  value={userType}
                  label="User Type"
                  error={userTypeError}
                  md={6}
                  required
                />
                <DateSelector
                  sxInside={{ maxWidth: "400px !important" }}
                  xsLabel={4}
                  mdInside={8}
                  xsInside={8}
                  label="Start Date"
                  onChangeAction={startDateHandler}
                  value={startDate}
                  onBlurAction={startDateValidation}
                  error={startDateError}
                />
                <DateSelector
                  sxInside={{ maxWidth: "400px !important" }}
                  xsLabel={4}
                  mdInside={8}
                  xsInside={8}
                  label="Expiry Date"
                  onChangeAction={expiryDateHandler}
                  value={expiryDate}
                  onBlurAction={expiryDateValidation}
                  error={expiryDateError}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        <PageFooter
          right={
            <Fragment>
              <Box
                sx={{
                  px: 2,
                }}
              >
                <Button
                  text="Submit"
                  // type="submit"
                  onClickAction={submitHandler}
                />
              </Box>

              <Box>
                <Button
                  fontSize="14px"
                  onMouseDownAction={() => navigate(-1)}
                  bgLight={true}
                  text="Cancel"
                />
              </Box>
            </Fragment>
          }
        />
      </form>
    </ThemeProvider>
  );
}
