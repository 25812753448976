import { Icon } from "@iconify/react";
import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import Button from "../../UI/Button/Button";
import Tile from "../../UI/Tile/Tile";
import { checkAnyPrivilage } from "../../utils/checkPrivilage";

export default function Jobs() {
  const location = useLocation();
  const navigate = useNavigate();

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "#",
      iconName: "mdi:tool-time",
      name: "Jobs Management",
    },
  ];

  return (
    <Fragment>
      <Breadcrumb options={breadCrumbData} />

      <Col lg={12}>
        <Row>
          {/* <Col lg={2} className="mb-4">
            <Tile
              small={true}
              icon="bi:p-circle-fill"
              to="/app/proda-jobs"
              text="PRODA"
            />
          </Col> */}
          {/* <Col lg={2} className="mb-4">
            <Tile
              small={true}
              icon="fa6-solid:capsules"
              to="/app/mims-jobs"
              text="MIMS"
            />
          </Col> */}
          {/* <Col lg={2} className="mb-4">
            <Tile
              small={true}
              icon="majesticons:money-hand"
              to="/app/mbs-jobs"
              text="MBS Alert"
            />
          </Col> */}
          <Col lg={2} className="mb-4">
            <Tile
              small={true}
              icon="mdi:shield-user"
              to="/app/subscription-jobs"
              text="Subscriptions"
            />
          </Col>
          <Col lg={2} className="mb-4">
            <Tile
              small={true}
              icon="bxs:error"
              to="/app/failed-jobs"
              text="Failed"
            />
          </Col>
        </Row>
      </Col>
    </Fragment>
  );
}
