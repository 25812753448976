import {
  Alert,
  Box,
  Grid,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";
import PageFooter from "../../Layout/PageFooter/PageFooter";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import Theme from "../../Theme/Theme";
import Button from "../../UI/Button/Button";
import TextBox from "../../UI/FormGroup/TextBox/TextBox";
import Loader from "../../UI/Loader/Loader";
import classes from "./Tests.module.css";
import UploadButton from "../../UI/FormGroup/UploadButton/UploadButton";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import storageService from "../../utils/localStorageHelpers";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import CautionAlert from "../../utils/CautionAlert";
import { useDispatch } from "react-redux";
import { saveSite } from "../../../redux/site";
import CryptoJS from "crypto-js";
import RadioGroup from "../../UI/FormGroup/RadioGroup/RadioGroup";
import SearchSite from "../../UI/SearchSite/SearchSite";
import moment from "moment";
import SelectBox from "../../UI/FormGroup/SelectBox/SelectBox";

export default function AddTest() {
  const widthIs14 = useMediaQuery("(max-width:1279px)");
  const widthIs930 = useMediaQuery("(max-width:930px)");
  const widthIs1630 = useMediaQuery("(max-width:1630px)");
  const location = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [success, setSuccess] = useState(null);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);

  const [code, setCode] = useState(null);
  const [codeError, setCodeError] = useState(null);
  const [name, setName] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [site, setSite] = useState(null);
  const [siteError, setSiteError] = useState(null);
  const [type, setType] = useState();
  const [typeError, setTypeError] = useState(null);

  const codeHandler = (e) => {
    setCodeError(null);
    setCode(e.target.value);
  };
  const codeValidation = () => {
    if (!code) {
      setCodeError("Required");
      return false;
    }
    return true;
  };

  const nameHandler = (e) => {
    setNameError(null);
    setName(e.target.value);
  };

  const nameValidation = () => {
    if (!name) {
      setNameError("Required");
      return false;
    }
    return true;
  };
  const typeHandler = (e) => {
    setTypeError(null);
    setType(e.target.value);
  };

  const typeValidation = (e) => {
    if (!type) {
      setTypeError("Required");
      return false;
    }
    return true;
  };

  async function postData() {
    let body = {
      name,
      code,
      type,
    };

    const result = await axios
      .post(
        `${backendUrl}/api/tests`,
        body,
        { withCredentials: true },
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((res) => {
        setLoader(false);
        navigate(`/app/tests`, {
          state: {
            showAlert: "Test has been added successfully",
          },
        });
      })
      .catch((e) => {
        setLoader(false);
        setError("Something went wrong. Please try again", true);
      });

    return result.data;
  }

  const submitValidation = () => {
    let proceed = true;
    proceed = proceed && codeValidation();
    proceed = proceed && nameValidation();
    proceed = proceed && typeValidation();

    if (proceed) {
      // All validations passed, proceed with submitting the form
      return true;
    } else {
      // Some validations failed, display error messages
      codeValidation();
      nameValidation();
      typeValidation();

      return false;
    }
  };

  const submitHandler = async (event) => {
    setLoader(true);
    event.preventDefault();

    let isProceed = submitValidation();

    if (!isProceed) {
      setLoader(false);
      return false;
    }

    await postData();

    setLoader(false);
  };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "/app/tests",
      iconName: "medical-icon:i-care-staff-area",
      name: "Tests Management",
    },
  ];

  const typeOptions = [
    {
      label: "Pathology",
      value: "Pathology",
    },
    {
      label: "Radiology",
      value: "Radiology",
    },
  ];
  return (
    <ThemeProvider theme={Theme}>
      <Loader open={loader} />
      <Breadcrumb options={breadCrumbData} />
      {error ? (
        <CautionAlert
          severity="error"
          open={error}
          content={error}
          onCloseActionB={() => setError(null)}
          okText="Ok"
          oneButton={true}
        />
      ) : null}

      {success ? (
        <CautionAlert
          severity="success"
          success={true}
          open={success}
          content={success}
          onCloseActionB={() => setSuccess(null)}
          okText="Ok"
          oneButton={true}
        />
      ) : null}

      <PageHeader left="Add Test" />
      <Loader open={loader} />

      <form>
        <Box className={classes.FormBox}>
          <Grid container direction="row" spacing={2}>
            {/* First Column */}
            <Grid
              container
              item
              xs={widthIs14 ? 12 : 6}
              direction="column"
              sx={{ maxWidth: widthIs14 ? "" : "590px !important" }}
              className={classes.Max}
            >
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Name"
                onChangeAction={nameHandler}
                value={name}
                onBlurAction={nameValidation}
                error={nameError}
                placeholder="Name"
                required
              />
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Code"
                onChangeAction={codeHandler}
                value={code}
                onBlurAction={codeValidation}
                error={codeError}
                placeholder="Code"
                required
              />
            </Grid>

            <Grid
              container
              item
              xs={widthIs14 ? 12 : 6}
              direction="column"
              className={classes.Max}
              sx={{
                marginLeft: widthIs1630 ? "" : "80px",
                maxWidth: widthIs14 ? "" : "590px !important",
              }}
            >
              <SelectBox
                xsLabel={4}
                options={typeOptions}
                xsInside={8}
                label="Type"
                onChangeAction={typeHandler}
                value={type}
                onBlurAction={typeValidation}
                error={typeError}
                noNone={true}
              />
            </Grid>
          </Grid>
        </Box>
        <PageFooter
          right={
            <Fragment>
              <Box
                sx={{
                  px: 2,
                }}
              >
                <Button
                  text="Submit"
                  // type="submit"
                  onClickAction={submitHandler}
                />
              </Box>

              <Box>
                <Button
                  fontSize="14px"
                  onMouseDownAction={() => navigate(-1)}
                  bgLight={true}
                  text="Cancel"
                />
              </Box>
            </Fragment>
          }
        />
      </form>
    </ThemeProvider>
  );
}
