import {
  Alert,
  Box,
  Grid,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";
import PageFooter from "../../Layout/PageFooter/PageFooter";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import Theme from "../../Theme/Theme";
import Button from "../../UI/Button/Button";
import TextBox from "../../UI/FormGroup/TextBox/TextBox";
import Loader from "../../UI/Loader/Loader";
import classes from "./Site.module.css";
import UploadButton from "../../UI/FormGroup/UploadButton/UploadButton";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import storageService from "../../utils/localStorageHelpers";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import CautionAlert from "../../utils/CautionAlert";
import { useDispatch } from "react-redux";
import { saveSite } from "../../../redux/site";
import CryptoJS from "crypto-js";
import CustomRadioGroup from "../../UI/FormGroup/RadioGroup/RadioGroup";

export default function EditOrganisation() {
  const widthIs14 = useMediaQuery("(max-width:1279px)");
  const widthIs930 = useMediaQuery("(max-width:930px)");
  const widthIs1630 = useMediaQuery("(max-width:1630px)");
  const location = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { id } = useParams();

  const [success, setSuccess] = useState(null);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);

  const [name, setName] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [mimsIntegrated, setMimsIntegrated] = useState(false);
  const [abn, setAbn] = useState(null);
  const [abnError, setAbnError] = useState(null);
  const [phone, setphone] = useState(null);
  const [phoneError, setphoneError] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [fax, setFax] = useState(null);
  const [faxError, setFaxError] = useState(null);
  const [headerLogo, setHeaderLogo] = useState(null);
  const [templateLogo, setTemplateLogo] = useState(null);
  const [headerLogoError, setHeaderLogoError] = useState(null);
  const [templateLogoError, setTemplateLogoError] = useState(null);
  const [deleteLogo, setDeleteLogo] = useState(null);
  const [headerDeleted, setHeaderDeleted] = useState(false);
  const [templateDeleted, setTemplateDeleted] = useState(false);
  const [apiError, setApiError] = useState(null);

  const [fileUploadError, setFileUploadError] = useState(null);

  const [imageURL, setimageURL] = useState(null);

  const [data, setData] = useState(null);
  const [noData, setNoData] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (data) {
      prefillData();
    }
  }, [data]);

  const loadData = () => {
    setLoader(true);
    if (location?.state?.data) {
      setLoader(false);
      setData(location.state.data);
      setNoData(false);
      return;
    } else {
      return axios({
        method: "GET",
        url: `${backendUrl}/api/all/orgaisation/${id}`,
        withCredentials: true,
      })
        .then(async (res) => {
          setLoader(false);
          let dt = res.data.data;
          setData(dt);
          setNoData(false);
        })
        .catch(() => {
          setLoader(false);
          setNoData(true);
          setApiError("Something went wrong. Please try again");
        });
    }
  };

  const prefillData = () => {
    setLoader(true);
    setName(data.organisation_name);
    setAbn(data.cin_number);
    setphone(data.contact_number);
    setEmail(data.contact_email);
    setFax(data.fax);
    setLoader(false);
  };

  const nameHandler = (e) => {
    let value = e?.target?.value;
    value = value ? value : null;
    setNameError(null);
    setName(value);
  };

  const nameValidation = () => {
    if (!name) {
      setNameError("Required");
      return false;
    }
    return true;
  };
  const abnHandler = (e) => {
    let value = e?.target?.value;
    value = value ? value : null;
    setAbnError(null);
    setAbn(value);
  };

  const abnValidation = (e) => {
    if (!abn) {
      setAbnError("Required");
      return false;
    }
    return true;
  };

  const phoneHandler = (e) => {
    let value = e?.target?.value;
    value = value ? value : null;
    setphoneError(null);
    setphone(value);
  };

  const phoneValidation = () => {
    if (!phone) {
      setphoneError("Required");
      return false;
    }
    return true;
  };

  const emailHandler = (e) => {
    let value = e?.target?.value;
    value = value ? value : null;
    setEmailError(null);
    setEmail(value);
  };

  const emailValidation = () => {
    if (!email) {
      setEmailError("Required");
      return false;
    }
    if (!isEmail(email) && email && email.length > 0) {
      setEmailError("Invalid email address");
      return false;
    }
    return true;
  };

  const isEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const faxHandler = (e) => {
    let value = e?.target?.value;
    value = value ? value : null;
    setFaxError(null);
    setFax(value);
  };

  const faxValidation = () => {
    if (!fax) {
      setFaxError("Required");
      return false;
    }
    return true;
  };

  async function postData() {
    let body = {
      organisation_name: name,
      cin_number: abn,
      contact_email: email,
      contact_number: phone,
      fax: fax,
    };

    const result = await axios
      .patch(
        `${backendUrl}/api/organisation/${data.id}`,
        body,
        { withCredentials: true },
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((res) => {
        setLoader(false);
        navigate("/app/organisations-management", {
          state: { showAlert: "Organisation has been successfully updated" },
        });
      })
      .catch((e) => {
        setLoader(false);
        setError("Something went wrong. Please try again");
      });

    setimageURL(result.imagePath);

    return result.data;
  }

  const headerLogoHandler = (event) => {
    setHeaderLogoError("");
    let img = new Image();
    img.src = window.URL.createObjectURL(event?.target?.files[0]);

    const logo = event.target.files[0];
    setHeaderLogo(logo);
  };

  const templateLogoHandler = (event) => {
    setTemplateLogoError("");
    let img = new Image();
    img.src = window.URL.createObjectURL(event?.target?.files[0]);

    const logo = event.target.files[0];
    setTemplateLogo(logo);
  };

  const fileUploadValidation = (event, file) => {
    // var allowedExtensions =/(\.jpg|\.jpeg|\.png)$/i;
    let file_size = event.target.files[0].size;

    if (file_size > 1020157) {
      if (file === "HEADER") {
        
        setHeaderLogoError("File size exceeds 1 MB");
      }
      if (file === "TEMPLATE") {
        
        setTemplateLogoError("File size exceeds 1 MB");
      }
    }
  };

  const submitValidation = () => {
    let proceed = true;
    proceed = proceed && nameValidation();
    proceed = proceed && phoneValidation();
    proceed = proceed && emailValidation();

    if (proceed) {
      // All validations passed, proceed with submitting the form
      return true;
    } else {
      // Some validations failed, display error messages
      nameValidation();
      phoneValidation();
      emailValidation();
      faxValidation();

      return false;
    }
  };

  const submitHandler = async (event) => {
    setLoader(true);
    event.preventDefault();

    if (
      data.organisation_name === name &&
      data.contact_email === email &&
      data.contact_number === phone &&
      data.cin_number === abn &&
      data.fax === fax
    ) {
      setLoader(false);
      setError("You have made no changes!");
      return;
    }

    let isProceed = submitValidation();

    if (!isProceed) {
      setLoader(false);
      return false;
    }

    if (headerLogoError || templateLogoError) {
      setLoader(false);
      fileUploadValidation(null, "HEADER");
      fileUploadValidation(null, "TEMPLATE");
      return;
    }

    await postData();

    setLoader(false);
  };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "/app/organisations-management",
      iconName: "bx:buildings",
      name: "Organisations Management",
    },
    {
      link: "#",
      iconName: "material-symbols:edit",
      name: "Edit Organisation",
    },
  ];

  const handleDelete = (logo) => {
    setDeleteLogo(logo);
  };

  const confirmDelete = async () => {
    if (deleteLogo === "template") {
      setTemplateDeleted(true);
    } else {
      setHeaderDeleted(true);
    }
    closeDelete();
  };

  const closeDelete = () => {
    setDeleteLogo();
  };

  const clearHeaderInput = () => {
    setHeaderLogoError();
    setHeaderLogo();
  };
  const clearTemplateInput = () => {
    setTemplateLogoError();
    setTemplateLogo();
  };

  const mimsIntegratedOptions = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];

  const retry = () => {
    setApiError(false);
    setTimeout(() => {
      loadData();
    }, 100);
  };

  return (
    <ThemeProvider theme={Theme}>
      <Loader open={loader} />
      <Breadcrumb options={breadCrumbData} />
      {error ? (
        <CautionAlert
          severity="error"
          open={error}
          content={error}
          onCloseActionB={() => setError(null)}
          okText="Ok"
          oneButton={true}
        />
      ) : null}

      {success ? (
        <CautionAlert
          severity="success"
          success={true}
          open={success}
          content={success}
          onCloseActionB={() => setSuccess(null)}
          okText="Ok"
          oneButton={true}
        />
      ) : null}
      <CautionAlert
        severity="error"
        open={deleteLogo}
        content={`Are you sure you want to delete ${deleteLogo} logo?`}
        onCloseActionB={confirmDelete}
        onCloseActionA={closeDelete}
        okText="Delete"
        cancelText="Cancel"
      />

      <CautionAlert
        severity="error"
        open={apiError}
        content={apiError}
        onCloseActionB={retry}
        onCloseActionA={() => setApiError(null)}
        okText="Retry"
        cancelText="Dismiss"
      />

      <PageHeader left="Edit Organisation" />
      <Loader open={loader} />

      <form>
        <Box className={classes.FormBox}>
          <Grid container direction="row" spacing={2}>
            {/* First Column */}
            <Grid
              container
              item
              xs={widthIs14 ? 12 : 6}
              direction="column"
              sx={{ maxWidth: widthIs14 ? "" : "590px !important" }}
              className={classes.Max}
            >
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Organisation Name"
                onChangeAction={nameHandler}
                onBlurAction={nameValidation}
                value={name}
                error={nameError}
                placeholder="State"
                required
              />
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="number"
                label="CIN"
                onChangeAction={abnHandler}
                value={abn}
              />
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="number"
                label="Phone Number"
                onChangeAction={phoneHandler}
                value={phone}
                onBlurAction={phoneValidation}
                error={phoneError}
                placeholder="Phone"
                required
              />
            </Grid>

            <Grid
              container
              item
              xs={widthIs14 ? 12 : 6}
              direction="column"
              className={classes.Max}
              sx={{
                marginLeft: widthIs1630 ? "" : "80px",
                maxWidth: widthIs14 ? "" : "590px !important",
              }}
            >
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Email"
                onChangeAction={emailHandler}
                value={email}
                onBlurAction={emailValidation}
                error={emailError}
                placeholder="Email"
                required
              />
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Fax Number"
                onChangeAction={faxHandler}
                value={fax}
              />
            </Grid>
          </Grid>
        </Box>
        <PageFooter
          right={
            <Fragment>
              <Box
                sx={{
                  px: 2,
                }}
              >
                <Button
                  text="Submit"
                  // type="submit"
                  onClickAction={submitHandler}
                />
              </Box>

              <Box>
                <Button
                  fontSize="14px"
                  onMouseDownAction={() => navigate(-1)}
                  bgLight={true}
                  text="Cancel"
                />
              </Box>
            </Fragment>
          }
        />
      </form>
    </ThemeProvider>
  );
}
