import React, { Fragment, useEffect, useRef, useState } from "react";
import classes from "./Header.module.css";
// import defaultLogo from "./../../../assets/Images/Logo.png";

import Link from "@mui/material/Link";
import Button from "../../UI/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import { Grid } from "@mui/material";
import { CssBaseline } from "@material-ui/core";
import { Box } from "@mui/system";
import { Icon } from "@iconify/react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Avatar, Divider, ListItemIcon, Typography } from "@mui/material";
import { Logout } from "@mui/icons-material";
import storageService from "../../utils/localStorageHelpers";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/auth/authState";
import { logoutUser } from "../../context/auth/authReducer";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "../../Theme/Theme";
import useTimer from "../../UI/useTimer/useTimer";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import { checkPrivilage } from "../../utils/checkPrivilage";
import { useDispatch, useSelector } from "react-redux";
import defaultLogo from "./../../../assets/Images/Logo.png";
import { useIdleTimer } from "react-idle-timer";
import {
  handlePause,
  handleResume,
  increment,
  pause,
} from "../../../redux/session";
import EulaPopup from "./NewAppointment/EulaPopup";
import { setCallMimsEula } from "../../../redux/auth";

const Header = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const counterRef = useRef();
  const location = useLocation();

  const sessionDetails = useSelector((state) => state.session);
  const { callMimsEula } = useSelector((state) => state.auth);
  const { timer } = useSelector((state) => state.session);
  const { activeSession } = useSelector((state) => state.session);
  const { active } = useSelector((state) => state.session);
  const { paused } = useSelector((state) => state.session);

  const canCreatePatient = checkPrivilage("PATIENTS", "CREATE");
  const canCreateAppointment = checkPrivilage("APPOINTMENTS", "CREATE");
  const canReadPatient = checkPrivilage("PATIENTS", "READ");

  const { site } = useSelector((state) => state.site);
  // const site = storageService.loadSite();
  const siteDetails = storageService.loadSite();
  const newLogo = "siteDetails[0].site_logo";

  const widthIs1190 = useMediaQuery("(max-width:1190px)");
  const widthIs14 = useMediaQuery("(max-width:1279px)");
  const widthIs930 = useMediaQuery("(max-width:930px)");
  const widthIs1630 = useMediaQuery("(max-width:1630px)");
  const widthIs1900 = useMediaQuery("(max-width:1900px)");
  const widthIs2000 = useMediaQuery("(max-width:2000px)");
  const widthIs2071 = useMediaQuery("(max-width:2071px)");

  const [anchorEl, setAnchorEl] = useState(null);
  const [openEula, setOpenEula] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [menu, setMenu] = useState(true);

  const handleTest = () => {
    navigate(`${window.location.pathname}`, { state: { menu: menu } });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      navigate(location.pathname, { replace: true });
    });
    return () => {
      dispatch(handlePause());
      clearInterval(counterRef.current);
    };
  }, []);

  useEffect(() => {
    handleTimer();
  }, [active, paused]);

  const onIdle = () => {
    authDispatch(logoutUser());
    storageService.logoutUser();
    navigate("/app/login", { state: { sessionExpired: true }, replace: true });
  };

  useIdleTimer({
    onIdle,
    crossTab: true,
    timeout: 1000 * 60 * 15,
    throttle: 500,
  });

  const handleTimer = () => {
    if (active && !paused) {
      counterRef.current = setInterval(() => {
        dispatch(increment());
      }, 1000);
    } else {
      clearInterval(counterRef.current);
    }
  };

  const loggedUser = storageService.loadUser();
  const truncate = (input) => {
    let length = 15;
    if (widthIs14) {
      length = 5;
    } else if (widthIs1630) {
      length = 7;
    } else if (widthIs1900) {
      length = 10;
    } else if (widthIs2000) {
      length = 13;
    } else if (widthIs2071) {
      length = 14;
    }
    return `${input.substring(0, length)}...`;
  };
  const [{ user }, authDispatch] = useAuthContext();

  function loggedout() {
    authDispatch(logoutUser());
    storageService.logoutUser();
    navigate("/app/login", { replace: true });
  }

  const formatTime = (timer) => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);
    return `${getHours} h : ${getMinutes} m : ${getSeconds} s`;
  };

  const [popUpOpen, setPopUpOpen] = useState(false);

  const handlePopupOpen = () => {
    setPopUpOpen(true);
  };

  const handlePopupClose = () => {
    setPopUpOpen(false);
  };

  return (
    <Fragment>
      <ThemeProvider theme={Theme}>
        <div className={classes.Header}>
          <Grid
            backgroundColor={Theme.palette.primaryColor}
            sx={{ px: "50px" }}
            container
          >
            <CssBaseline />

            <Box
              component={Grid}
              item
              lg={1}
              md={1}
              xs={2}
              display={{ xs: "none", lg: "block" }}
            >
              <div className={classes.Logo}>
                <img
                  alt="Logo"
                  src={site?.[0]?.site_logo ? site?.[0].site_logo : defaultLogo}
                  className={`img-fluid ${classes.LogoImg}`}
                />
              </div>
            </Box>

            <Box
              component={Grid}
              item
              xs={2}
              display={{ lg: "none", md: "flex", xs: "flex" }}
              borderRight={{ lg: "none", xs: "1px solid #fff" }}
              square
              sx={{
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={handleTest}
            >
              <Icon className={classes.MenuIcon} icon="gg:menu" />
            </Box>

            <Box
              component={Grid}
              container
              xs={2}
              sm={2}
              lg={2.5}
              gap={1}
              sx={{
                alignItems: "center",
                padding: "16px",
              }}
              justifyContent={{ lg: "right", md: "left" }}
              className={classes.MenuGrid}
            ></Box>

            <Box
              component={Grid}
              container
              xs={6}
              sm={6}
              lg={7.3}
              gap={2}
              sx={{
                alignItems: "center",
                padding: "16px",
              }}
              justifyContent={{ lg: "right", md: "left" }}
              className={classes.MenuGrid}
            >
              <Box
                component={Grid}
                sx={{
                  width: widthIs1190
                    ? "180px !important"
                    : widthIs1630
                    ? "110px !important"
                    : "200px !important",
                }}
              >
                {/* <Paper
                  component="form"
                  xs={{ width: 420 }}
                  sx={{
                    p: "2px 4px",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    height: "36px",
                    borderRadius: 0,
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                  />
                  <IconButton
                    type="submit"
                    sx={{ p: "8px" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper> */}
              </Box>
            </Box>

            <Box
              component={Grid}
              container
              xs={1.5}
              sm={1}
              display={{ xs: "flex", lg: "none" }}
              sx={{
                alignItems: "center",
                padding: "16px 0",
                justifyContent: "center",
              }}
              className={classes.Icon}
            >
              <Icon icon="radix-icons:dashboard" />
            </Box>

            <Box
              component={Grid}
              container
              xs={2}
              sm={1}
              lg={1.2}
              gap={1}
              justifyContent={{ xs: "left", lg: "right" }}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              onClick={handleClick}
            >
              <Box component={Grid} item className={classes.Icon}>
                <Icon icon="bxs:user" className={classes.Icon} />

                <Icon
                  icon="typcn:arrow-sorted-down"
                  className={classes.arrow}
                />
              </Box>

              <Box
                component={Grid}
                item
                className={classes.Link}
                display={{ xs: "none", lg: "block" }}
                pt={1}
              >
                <Link className={classes.Link}>
                  {loggedUser && truncate(loggedUser?.user?.name)}
                </Link>
              </Box>
            </Box>
          </Grid>
        </div>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Typography sx={{ mx: 5, my: 2 }}>
            Role: {loggedUser ? loggedUser.user.role.name : <></>}
          </Typography>

          <Divider />

          <MenuItem onClick={() => navigate(`/app/profile`)}>
            <Avatar /> Profile
          </MenuItem>

          <MenuItem onClick={loggedout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </ThemeProvider>
    </Fragment>
  );
};

export default Header;
